import {
  AIAggregatedDocType,
  AICommonDocModelParams
} from 'shared/entities/smartbotAI';
import { apiUrls } from 'shared/entities/domain';

import { FieldModel } from '../form';

import { AIDocModel } from './AIDocModel';

export class AICommonDocModel<T extends AIAggregatedDocType> extends AIDocModel<
  T,
  string
> {
  readonly id: FieldModel<string>;

  constructor(params: AICommonDocModelParams<T>) {
    super(params);
    this.id = new FieldModel<string>(params.id);
  }

  remove = async (): Promise<BaseResponse> => {
    if (this.removingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.removingStage.loading();

    const response = await this.rootStore.networkStore.api(
      apiUrls.AI_DELETE_DOC,
      {
        method: 'POST',
        data: {
          id: this.id.value
        }
      }
    );

    if (response.isError) {
      this.removingStage.error();
    } else {
      this.removingStage.success();
    }

    return response;
  };
}
