import * as React from 'react';

import { TranslationString } from 'shared/entities/localization';
import { TBankIcon } from 'shared/newComponents/icons';

import {
  mapUserPaymentMethodKindEntities,
  UserPaymentMethodKind
} from '../userPayment';
import { CommonIconProps } from '../components/Icon';

export enum IntegrationType {
  google = 'google',
  domains = 'domains',
  api = 'api',
  getCourse = 'get_course',
  amoCRM = 'amocrm',
  prodamus = 'prodamus'
}

export enum IntegrationPageType {
  common = 'common',
  modal = 'modal'
}

export type IntegrationTab = UserPaymentMethodKind | IntegrationType;

export type IntegrationTabType = {
  id: IntegrationTab;
  title: TranslationString | string;
  entitiesTitle: TranslationString;
  isNew?: boolean;
  isBeta?: boolean;
  onlyForSa?: boolean;
  fullIcon?: React.FC<CommonIconProps>;
};

export const mapIntegrationTabToConfig: Record<
  IntegrationTab,
  IntegrationTabType
> = {
  [UserPaymentMethodKind.yookassa]: {
    id: UserPaymentMethodKind.yookassa,
    title:
      mapUserPaymentMethodKindEntities[UserPaymentMethodKind.yookassa].title,
    entitiesTitle: (t) =>
      t('integrations.yookassa.entitiesTitle', {
        ns: 'entities'
      })
  },
  [UserPaymentMethodKind.qiwi]: {
    id: UserPaymentMethodKind.qiwi,
    title: mapUserPaymentMethodKindEntities[UserPaymentMethodKind.qiwi].title,
    entitiesTitle: (t) =>
      t('integrations.qiwi.entitiesTitle', {
        ns: 'entities'
      })
  },
  [UserPaymentMethodKind.yooMoney]: {
    id: UserPaymentMethodKind.yooMoney,
    title:
      mapUserPaymentMethodKindEntities[UserPaymentMethodKind.yooMoney].title,
    entitiesTitle: (t) =>
      t('integrations.yooMoney.entitiesTitle', {
        ns: 'entities'
      })
  },
  [UserPaymentMethodKind.tg]: {
    id: UserPaymentMethodKind.tg,
    title: mapUserPaymentMethodKindEntities[UserPaymentMethodKind.tg].title,
    entitiesTitle: (t) =>
      t('integrations.tg.entitiesTitle', {
        ns: 'entities'
      })
  },
  [UserPaymentMethodKind.prodamus]: {
    id: UserPaymentMethodKind.prodamus,
    title:
      mapUserPaymentMethodKindEntities[UserPaymentMethodKind.prodamus].title,
    entitiesTitle: (t) =>
      t('integrations.prodamus.entitiesTitle', {
        ns: 'entities'
      })
  },
  [UserPaymentMethodKind.tinkoff]: {
    id: UserPaymentMethodKind.tinkoff,
    title:
      mapUserPaymentMethodKindEntities[UserPaymentMethodKind.tinkoff].title,
    entitiesTitle: (t) =>
      t('integrations.tinkoff.entitiesTitle', {
        ns: 'entities'
      }),
    fullIcon: TBankIcon
  },
  [IntegrationType.google]: {
    id: IntegrationType.google,
    title: (t) =>
      t('integrations.google.title', {
        ns: 'entities'
      }),
    entitiesTitle: (t) =>
      t('integrations.google.entitiesTitle', {
        ns: 'entities'
      })
  },
  [IntegrationType.domains]: {
    id: IntegrationType.domains,
    title: (t) =>
      t('integrations.domains.title', {
        ns: 'entities'
      }),
    entitiesTitle: (t) =>
      t('integrations.domains.entitiesTitle', {
        ns: 'entities'
      })
  },
  [IntegrationType.api]: {
    id: IntegrationType.api,
    title: 'API Smartbot Pro',
    entitiesTitle: (t) =>
      t('integrations.api.entitiesTitle', {
        ns: 'entities'
      })
  },
  [IntegrationType.getCourse]: {
    id: IntegrationType.getCourse,
    title: 'GetCourse',
    entitiesTitle: (t) =>
      t('integrations.getCourse.entitiesTitle', {
        ns: 'entities'
      })
  },
  [IntegrationType.amoCRM]: {
    id: IntegrationType.amoCRM,
    title: 'amoCRM',
    entitiesTitle: (t) =>
      t('integrations.amoCRM.entitiesTitle', {
        ns: 'entities'
      }),
    isBeta: true
  }
};

export const integrationTabsOrder: IntegrationTab[] = [
  UserPaymentMethodKind.yookassa,
  UserPaymentMethodKind.yooMoney,
  UserPaymentMethodKind.qiwi,
  UserPaymentMethodKind.tg,
  UserPaymentMethodKind.prodamus,
  UserPaymentMethodKind.tinkoff,
  IntegrationType.getCourse,
  IntegrationType.amoCRM,
  IntegrationType.google,
  IntegrationType.domains,
  IntegrationType.api
];

export enum TinkoffTerminalCreationError {
  useAnotherTinkoffTerminal = 'use_another_tinkoff_terminal'
}

export enum ActionPlace {
  stub = 'stub',
  header = 'header'
}

export type ActionProps = {
  place: ActionPlace;
};

export type IntegrationProps =
  | {
      page: IntegrationPageType.common;
    }
  | { page: IntegrationPageType.modal; onClose: () => void };
