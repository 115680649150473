import { ILoadingStageModel } from '../loadingStage';
import { AreaSize } from '../geometry';
import { IRootStore } from '../store/rootStore';
import { IField } from '../form';
import { IList, INewList } from '../list';
import { TranslationString } from '../localization';
import { SelectorItem } from '../components/Selector';
import { IScenarioData } from '../scenario';

import { IProductOptionModel } from './options';
import {
  CartMode,
  HandleProductPageMode,
  ShopbackCardImgProportion,
  ShopbackCategoryViewMode,
  ShopbackViewMode
} from './server';

/**
 * Режим просмотра страницы главного продукта
 */
export enum OverviewMainProductPageMode {
  // Редактирование главного продукта
  mainProduct = 'main_product',

  // Просмотр модификаций главного продукта
  modifications = 'modifications'
}

export enum HandleCategoryPageMode {
  create = 'create', // обычное создание
  edit = 'edit', // обычное редактирование
  initialCreate = 'initial_create', //первоначальное создание
  initialEdit = 'initial_edit', //редактирование после первоначального создания(при клике "назад")
  createChild = 'create_child', //создание категории-ребенка с заполненным родителем
  upload = 'upload' //загрузка товаров
}

export enum UploadProductsMode {
  file = 'file',
  manual = 'manual'
}

export enum ShopbackTypeOfCards {
  fixed = 'fixed',
  various = 'various '
}

export interface IShopbackProject {
  id: number;
  uuid: string;
  title: string;
  smartbotProjectId: string;
  tgToken: string;
  viewMode: ShopbackViewMode;
  categoryViewMode: ShopbackCategoryViewMode;
  showSearch: boolean;
  showCategory: boolean;
  cartMode: CartMode;

  supportElectronicGoods: boolean;
  supportModification: boolean;

  mainPageTitle: string | null;
  cartTitle: string | null;
  cartButtonTitle: string | null;
  productCallToActionTitle: string | null;
  productInListCallToActionTitle: string | null;
  finishButtonTitle: string | null;
  currency: string | null;
  scenarios: IScenarioData[];
  alignCards: boolean;
  cardImgProportion: ShopbackCardImgProportion | null;
}

export interface IShopbackAttachment {
  id: number;
  title: string;
  authorId: number | null;
  bigSize: AreaSize;
  smallSize: AreaSize;
  bigImageUrl: string;
  smallImageUrl: string;
  url: string;
  created: Date;
  isProcessed: boolean;
  isValid: boolean;
}

export type HandleCreateMainProductPageModeParams = {
  mode: HandleProductPageMode.create;
  productType: ShopbackProductType.main;
  categoryId?: number;
};

export type HandleCreateMainProductPageModeSearchParams = {
  mode: HandleProductPageMode.create;
  productType: ShopbackProductType.main;
  categoryId?: string;
};

export type HandleCreateModificationProductPageModeParams = {
  mode: HandleProductPageMode.create;
  productType: ShopbackProductType.modification;
  mainProductId: number;
};

export type HandleCreateModificationProductPageModeSearchParams = {
  mode: HandleProductPageMode.create;
  productType: ShopbackProductType.modification;
  mainProductId: string;
};

export type HandleEditProductPageModeParams = {
  mode: HandleProductPageMode.edit;
  productType: ShopbackProductType;
  id: number;
};

export type HandleEditProductPageModeSearchParams = {
  mode: HandleProductPageMode.edit;
  productType: ShopbackProductType;
  id: string;
};

export type InitialProductPageModeParams = {
  mode: HandleProductPageMode.initialCreate;
  productType: ShopbackProductType.main;
  categoryId: number;
};

export type InitialProductPageModeSearchParams = {
  mode: HandleProductPageMode.initialCreate;
  productType: ShopbackProductType.main;
  categoryId: string;
};

/**
 * Параметры, связанные с просмотром главного продукта
 */
export type MainProductOverviewModeSearchParams = {
  mode: OverviewMainProductPageMode;
  id: string;
};

export type MainProductOverviewModeParams = {
  mode: OverviewMainProductPageMode;
  id: number;
};

export type HandleProductPageModeParams =
  | HandleCreateMainProductPageModeParams
  | HandleCreateModificationProductPageModeParams
  | HandleEditProductPageModeParams;

export type HandleCommonProductPageParams =
  | InitialProductPageModeSearchParams
  | HandleProductPageModeSearchParams;

export type HandleProductPageModeSearchParams =
  | HandleCreateMainProductPageModeSearchParams
  | HandleCreateModificationProductPageModeSearchParams
  | HandleEditProductPageModeSearchParams;

export interface IShopbackAttachmentModel extends IShopbackAttachment {
  hovered: IField<boolean>;
}

/**
 * Интерфейс списка продуктов любого типа
 */
export interface IShopProductsListStore<
  ShopProductModelT extends IShopProductModel = IShopProductModel
> {
  products: INewList<ShopProductModelT, number>;
  currency?: string | null;

  isInitialLoadingStage: boolean;

  init(): BaseResponse | Promise<BaseResponse>;
  loadProducts(): Promise<BaseResponse>;
  handleLoadMore?(): Promise<void>;
}

/**
 * Интерфейс списка продуктов, которые можно отмечать выбранными и удалять
 */
export interface IShopSelectableProductsListStore<
  ProductModelT extends ISelectableShopProductModel = ISelectableShopProductModel
> extends IShopProductsListStore<ProductModelT> {
  productsDeletingStage: ILoadingStageModel;
}

export interface IShopbackCategoriesAndProductsListStore {
  rootStore: IRootStore;
  draggingProductId: IField<number | null>;
  productsCategoryId: IField<number | null>;
  readonly project: IField<IShopbackProject | null>;

  canDrop: (category: IShopCategoryModel) => boolean;
}

export interface IShopbackProductsListStore
  extends IShopSelectableProductsListStore {
  shopbackCategoriesAndProductsListStore: IShopbackCategoriesAndProductsListStore;
  initializeReactions: () => void;
}

export interface IShopbackCategoriesStore {
  shopId: number;
  categories: INewList<IShopCategoryModel, number>;
  loadCategories(shopId: number): Promise<BaseResponse>;
  isCategoryShown(categoryId: number): boolean;
  readonly searchText: IField<string>;
  getCategoryChildIds(categoryId: number | null): BaseResponse<number[]>;
}

export interface IShopbackCategoriesListStore {
  shopbackCategoriesAndProductsListStore: IShopbackCategoriesAndProductsListStore;

  draggingCategoryId: IField<number | null>;
}

export interface IShopCategory {
  readonly childrenIds: number[];
  readonly id: number;
  readonly name: string;
}

export interface IShopCategoryModel extends IShopCategory {
  readonly selected: IField<boolean>;
  readonly opened: IField<boolean>;
  readonly hovered: IField<boolean>;
  readonly attachment: IShopbackAttachmentModel | null;
  readonly parentId: IField<number | null>;
  readonly parentsId: IField<number[]>;
  readonly isVisible: IField<boolean>;

  readonly shopbackCategoriesStore: IShopbackCategoriesStore;

  readonly childrenIds: number[];

  selectCategory: () => void;
  unselectParents: () => void;
  unselect: () => void;
  isChildren: () => boolean;
  openWithChildren: (open: boolean) => void;
  selectWithChildren: (select: boolean) => void;
  hasChildrenWithId: (id: number) => boolean;
  toggleOpenWithChildren: () => void;
  removeChild: (id: number) => void;
  addChild: ({ id, start }: { id: number; start?: boolean }) => void;
  addChildByIndex: ({ id, index }: { id: number; index: number }) => void;
  onHover: (value: boolean) => void;
}

export enum GrowthToolsDndType {
  shopbackCategory = 'shopback-category'
}

export interface IShopProduct {
  id: number;
}

/**
 * Интерфейс главного товара с информацией,
 * минимально необходимой для воспроизведедения модификации
 */
export interface IMainProductShortModel {
  id: number;
  name: string;
}

export interface IShopProductModel {
  id: number;
  name: string;
  price: IField<string>;
  category: IField<string>;
  type: ShopbackProductType;
  externalId: string;
  categoryId: IField<number>;
  projectId: number;
  attachments: IShopbackAttachment[] | null;
  count: string | null;
  description: string;
  isVisible: IField<boolean>;
  hasModifications: boolean;
  options: IProductOptionModel[];
  mainProduct: IMainProductShortModel | null;
}

export interface ISelectableShopProductModel extends IShopProductModel {
  selected: IField<boolean>;
}

export type MainProductShortModelParams = {
  id: number;
  name: string;
};

export enum ShopbackProductType {
  main = 'main',
  modification = 'modification'
}

export interface IShopbackProductEditModel {
  id: IField<number | null>;
  title: IField;
  project: number;
  type: ShopbackProductType;

  // У основного товара можно редактировать категорию, у модификации - нельзя
  categoryId: IField<number | null>;
  attachments: IList<IShopbackAttachmentModel, number>;
  externalId: IField;
  description: IField;
  price: IField;
  count: IField;
  isVisible: IField<boolean>;
  draggingId: IField<number | null>;
  topDropSpotHovered: IField<boolean>;
}

export interface IShopProductsList {
  products: IShopProduct[];
}

export interface IShopModificationsListStore
  extends IShopSelectableProductsListStore {
  shopbackProject: IField<IShopbackProject | null>;
  mainProductId: number;
  selectedProducts: ISelectableShopProductModel[];
  productsDeletingStage: ILoadingStageModel;
  loadingStage: ILoadingStageModel;
  deleteProducts(ids: number[]): Promise<BaseResponse>;
  unselectAll(): void;
}

export type ShopbackCategoriesSelectorItem = {
  parentId: number | null;
  parentsId: number[];
  childIds: number[];
  url?: string;
  id: number | null;
  title: TranslationString | string;
};

export type ShopbackHandleCategoryParams =
  | { mode: HandleCategoryPageMode.upload; shopId: number }
  | { mode: HandleCategoryPageMode.edit; id: number; shopId: number }
  | { mode: HandleCategoryPageMode.create; shopId: number }
  | { mode: HandleCategoryPageMode.initialCreate; shopId: number }
  | { mode: HandleCategoryPageMode.initialEdit; id: number; shopId: number }
  | {
      mode: HandleCategoryPageMode.createChild;
      parentId: string;
      shopId: number;
    };

export const MAX_CATEGORIES_COUNT = 10;

export const mapCardImgProportionToSelectorItem: Record<
  ShopbackCardImgProportion,
  SelectorItem
> = {
  [ShopbackCardImgProportion.nineSixteen]: {
    id: ShopbackCardImgProportion.nineSixteen,
    title: '9:16'
  },
  [ShopbackCardImgProportion.fourFive]: {
    id: ShopbackCardImgProportion.fourFive,
    title: '4:5'
  },
  [ShopbackCardImgProportion.oneOne]: {
    id: ShopbackCardImgProportion.oneOne,
    title: '1:1'
  },
  [ShopbackCardImgProportion.sixteenNine]: {
    id: ShopbackCardImgProportion.sixteenNine,
    title: '16:9'
  }
};

export type MoveCategoryData = {
  parentId: number | null;
  parentChildIds: number[];
};

export enum DropInsideType {
  before = 'before',
  inside = 'inside'
}
