import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction
} from 'mobx';
import debounce from 'lodash/debounce';

import {
  ScenarioCommonModel,
  ScenarioEditModel,
  ScenarioReactionModel
} from 'shared/models/scenario';
import {
  reactionsOrder,
  ScenarioCommonServer,
  ScenarioIsNoLongerTemplateDataType,
  ScenarioKind,
  ScenarioReactionServer,
  ScenariosEventType
} from 'shared/entities/scenario';
import { apiUrls } from 'shared/entities/domain';
import { IScenariosStore } from 'shared/entities/store/scenariosStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStage } from 'shared/entities/meta';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { Bucket } from 'shared/entities/bucket';
import ListModel from 'shared/models/ListModel';
import { OpenStateModel } from 'shared/models/openState';
import { AnalyticsEvent } from 'shared/entities/analytics';
import PubSubObserver from 'lib/PubSubObserver';
import { FieldModel } from 'shared/models/form';
import { LoadingStageModel } from 'shared/models/loadingStage';
import getBooleanValueFromLS from 'shared/utils/getBooleanValueFromLS';
import { createScenariosActionsOpenedKey } from 'shared/entities/localStorage';
import { localStorageHandler } from 'stores/localStorageHandler';

const SCENARIOS_LIMIT = 20;
const DEBOUNCE_DELAY = 1000;

export default class ScenariosStore
  extends PubSubObserver
  implements IScenariosStore
{
  private _publishStage: LoadingStage = LoadingStage.NOT_STARTED;
  private _resetStage: LoadingStage = LoadingStage.NOT_STARTED;
  private _hasChanges = false;
  private _kind: ScenarioKind;
  readonly searchingStage: LoadingStageModel = new LoadingStageModel();

  readonly searchText: FieldModel = new FieldModel<string>('');
  readonly rootStore: IRootStore;

  readonly commonScenarios: ListModel<ScenarioCommonModel> =
    new ListModel<ScenarioCommonModel>();

  readonly reactionScenarios: ListModel<ScenarioReactionModel> =
    new ListModel<ScenarioReactionModel>();

  readonly searchModalState: OpenStateModel = new OpenStateModel();

  scenariosActionsOpened: boolean;

  constructor({
    rootStore,
    kind
  }: {
    rootStore: IRootStore;
    kind: ScenarioKind;
  }) {
    super();

    this.rootStore = rootStore;
    this._kind = kind;
    this.scenariosActionsOpened = this.rootStore.projectId
      ? getBooleanValueFromLS(
          createScenariosActionsOpenedKey(this.rootStore.projectId)
        ) ?? true
      : true;

    makeObservable<
      ScenariosStore,
      '_publishStage' | '_resetStage' | '_hasChanges' | '_kind'
    >(this, {
      commonScenarios: observable,
      reactions: observable,
      _publishStage: observable,
      _resetStage: observable,
      _hasChanges: observable,
      _kind: observable,
      searchModalState: observable,
      rootStore: observable,
      scenariosActionsOpened: observable,

      loadReactionScenarios: action.bound,
      loadCommonScenarios: action.bound,
      create: action,
      reset: action,
      publish: action.bound,
      update: action,
      copy: action,
      resetChanges: action.bound,
      resetScenariosChanges: action,
      removeScenario: action,
      changeKind: action,
      changeScenariosActionsOpened: action,

      resetStage: computed,
      publishStage: computed,
      hasChanges: computed,
      kind: computed
    });
  }

  initializeReactions = (): void => {
    this.addReaction({
      key: 'kind',
      reaction: reaction(
        () => this._kind,
        (kind) =>
          kind === ScenarioKind.common
            ? this.loadCommonScenarios({ initial: true })
            : this.loadReactionScenarios({ initial: true })
      )
    });

    this.addReaction({
      key: 'search',
      reaction: reaction(
        () => this.searchText.value,
        () => this.debouncedSearchScenarios()
      )
    });

    this.subscribeEvent(
      ScenariosEventType.SCENARIO_IS_NO_LONGER_A_TEMPLATE,
      this.handleDeleteFromTemplates
    );
  };

  debouncedSearchScenarios = debounce(async () => {
    this.searchingStage.loading();
    const response = await this.loadCommonScenarios({ initial: true });
    if (response.isError) {
      this.searchingStage.error();
    } else {
      this.searchingStage.success();
    }
  }, DEBOUNCE_DELAY);

  get kind(): ScenarioKind {
    return this._kind;
  }

  get hasChanges(): boolean {
    return this._hasChanges;
  }

  get resetStage(): LoadingStage {
    return this._resetStage;
  }

  get publishStage(): LoadingStage {
    return this._publishStage;
  }

  changeKind = (value: ScenarioKind): void => {
    this._kind = value;
  };

  async initialLoad(): Promise<BaseResponse> {
    if (this._kind === ScenarioKind.common) {
      return this.loadCommonScenarios();
    }
    return this.loadReactionScenarios();
  }

  async loadCommonScenarios(
    {
      initial,
      limit = SCENARIOS_LIMIT
    }: { initial: boolean; limit?: number } = {
      initial: false,
      limit: SCENARIOS_LIMIT
    }
  ): Promise<BaseResponse> {
    if (this.commonScenarios.loadingStage === LoadingStage.LOADING) {
      return { isError: true };
    }

    this.commonScenarios.setIsInitialLoad(initial);
    this.commonScenarios.setLoadingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<{
      scenarios: ScenarioCommonServer[];
      has_changes: boolean;
    }>(apiUrls.SCENARIOS_LIST, {
      method: 'GET',
      data: {
        kind: ScenarioKind.common,
        offset: initial ? 0 : this.commonScenarios.length,
        limit,
        q: this.searchText.value || undefined
      }
    });

    if (!response.isError) {
      const { scenarios } = response.data;

      const { entities, keys } = scenarios.reduce(
        (acc, scenario) => ({
          ...acc,
          entities: {
            ...acc.entities,
            [scenario._id]: ScenarioCommonModel.fromJson(
              scenario,
              this.rootStore
            )
          },
          keys: [...acc.keys, scenario._id]
        }),
        {
          entities: {},
          keys: []
        }
      );

      runInAction(() => {
        this.commonScenarios.addEntities({
          entities,
          keys,
          initial
        });
        this.commonScenarios.setHasMore(keys.length === limit);
        this._hasChanges = response.data.has_changes;

        this.commonScenarios.setLoadingStage(LoadingStage.SUCCESS);
        this.commonScenarios.setIsInitialLoad(false);
      });
      return { isError: false };
    } else {
      runInAction(() => {
        this.commonScenarios.setHasMore(false);
        this.commonScenarios.setLoadingStage(LoadingStage.ERROR);
        this.commonScenarios.setIsInitialLoad(false);
      });
      return { isError: true };
    }
  }

  async loadReactionScenarios(
    {
      initial,
      limit = SCENARIOS_LIMIT
    }: { initial: boolean; limit?: number } = {
      initial: false,
      limit: SCENARIOS_LIMIT
    }
  ): Promise<BaseResponse> {
    if (this.reactionScenarios.loadingStage === LoadingStage.LOADING) {
      return { isError: true };
    }

    this.reactionScenarios.setIsInitialLoad(initial);
    this.reactionScenarios.setLoadingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<{
      scenarios: ScenarioReactionServer[];
      has_changes: boolean;
    }>(apiUrls.SCENARIOS_LIST, {
      method: 'GET',
      data: {
        kind: ScenarioKind.reaction,
        offset: initial ? 0 : this.reactionScenarios.length,
        limit
      }
    });

    if (!response.isError) {
      const { scenarios } = response.data;

      const { keys, entities } = reactionsOrder.reduce(
        (acc, reactionKind) => {
          const reactionRaw = scenarios.find(
            (scenario) => scenario.reaction === reactionKind
          );

          return {
            ...acc,
            keys: [...acc.keys, reactionKind],
            entities: {
              ...acc.entities,
              [reactionKind]: reactionRaw
                ? ScenarioReactionModel.fromJson(reactionRaw, this.rootStore)
                : ScenarioReactionModel.fromDefaultParams(
                    this.rootStore,
                    reactionKind
                  )
            }
          };
        },
        { keys: [], entities: {} } as {
          keys: string[];
          entities: Record<string, ScenarioReactionModel>;
        }
      );

      runInAction(() => {
        this.reactionScenarios.addEntities({
          entities,
          keys,
          initial
        });

        this.reactionScenarios.setHasMore(keys.length === limit);
        this._hasChanges = response.data.has_changes;
        this.reactionScenarios.setLoadingStage(LoadingStage.SUCCESS);
        this.reactionScenarios.setIsInitialLoad(false);
      });
      return { isError: false };
    } else {
      runInAction(() => {
        this.reactionScenarios.setHasMore(false);
        this.reactionScenarios.setLoadingStage(LoadingStage.ERROR);
        this.reactionScenarios.setIsInitialLoad(false);
      });
      return { isError: true };
    }
  }

  async resetChanges(): Promise<void> {
    if (this._resetStage === LoadingStage.LOADING) {
      return;
    }

    this._resetStage = LoadingStage.LOADING;

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.PROJECTS_RESET,
      {
        method: 'POST'
      }
    );

    if (!isError) {
      this.rootStore.appNotificationsStore.open({
        title: (t) =>
          t('ScenariosStore.notifications.resetSuccess', {
            ns: 'stores'
          }),
        type: AppNotificationType.success
      });

      runInAction(() => {
        this._hasChanges = false;
        this.resetScenariosChanges();
        this._resetStage = LoadingStage.SUCCESS;
      });
    } else {
      runInAction(() => {
        this._resetStage = LoadingStage.ERROR;
      });
    }
  }

  async publish(): Promise<BaseResponse> {
    if (this._publishStage === LoadingStage.LOADING) {
      return { isError: true };
    }

    this._publishStage = LoadingStage.LOADING;

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.PROJECTS_PUBLISH,
      {
        method: 'POST'
      }
    );

    if (!isError) {
      this.rootStore.appNotificationsStore.open({
        title: (t) =>
          t('ScenariosStore.notifications.publishSuccess', {
            ns: 'stores'
          }),
        type: AppNotificationType.success
      });

      runInAction(() => {
        this._hasChanges = false;
        this.resetScenariosChanges();
        this._publishStage = LoadingStage.SUCCESS;
      });
    } else {
      runInAction(() => {
        this._publishStage = LoadingStage.ERROR;
      });
    }
    return {
      isError
    };
  }

  resetScenariosChanges = () => {
    this.commonScenarios.items.forEach((scenario) =>
      scenario.setHasChanges(false)
    );
    this.reactionScenarios.items.forEach((scenario) =>
      scenario.setHasChanges(false)
    );
  };

  async create(
    editScenarioModel: ScenarioEditModel
  ): Promise<{ stage: LoadingStage; scenario: ScenarioCommonModel | null }> {
    if (this.commonScenarios.creatingStage === LoadingStage.LOADING) {
      return { stage: this.commonScenarios.creatingStage, scenario: null };
    }
    this.commonScenarios.setCreatingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<{
      scenario: ScenarioCommonServer;
    }>(apiUrls.SCENARIOS_CREATE, {
      method: 'POST',
      data: {
        ...editScenarioModel.toJson(),
        bucket: Bucket.dev
      }
    });

    let newScenario: ScenarioCommonModel | null = null;

    if (!response.isError) {
      runInAction(() => {
        newScenario = ScenarioCommonModel.fromJson(
          response.data.scenario,
          this.rootStore
        );

        this.commonScenarios.addEntity({
          entity: newScenario,
          key: newScenario.id,
          start: true
        });
        this.commonScenarios.setCreatingStage(LoadingStage.SUCCESS);
        this.rootStore.analyticsStore.sendEvent(
          AnalyticsEvent.scenarioCreationOk,
          {
            created_scenario_name: newScenario.name
          }
        );
      });
    } else {
      runInAction(() => {
        this.commonScenarios.setCreatingStage(LoadingStage.ERROR);
      });
    }

    return { stage: this.commonScenarios.creatingStage, scenario: newScenario };
  }

  async copy(
    editScenarioModel: ScenarioEditModel
  ): Promise<BaseResponse<ScenarioCommonModel>> {
    if (!editScenarioModel.id) {
      return {
        isError: true
      };
    }

    const scenario = this.commonScenarios.getEntity(editScenarioModel.id);

    if (!scenario) {
      return {
        isError: true
      };
    }

    const response = await scenario.copy(editScenarioModel);

    if (!response.isError) {
      runInAction(() => {
        this.commonScenarios.entities[response.data.id] = response.data;
        this.commonScenarios.keys.push(response.data.id);
        this._hasChanges = true;
      });
    }

    return response;
  }

  async update(editScenarioModel: ScenarioEditModel): Promise<BaseResponse> {
    if (editScenarioModel.id === null) {
      return {
        isError: true
      };
    }

    const scenario = this.commonScenarios.getEntity(editScenarioModel.id);

    if (!scenario) {
      return {
        isError: true
      };
    }

    return scenario.update({
      channelIds: new FieldModel(editScenarioModel.channelIds),
      name: editScenarioModel.name
    });
  }

  async removeScenario(id: string): Promise<LoadingStage> {
    const scenario = this.commonScenarios.getEntity(id);

    if (!scenario) {
      return LoadingStage.ERROR;
    }

    const stage = await scenario.remove();

    if (stage === LoadingStage.SUCCESS) {
      this.commonScenarios.removeEntity(id);

      if (!this.commonScenarios.length) {
        this._hasChanges = false;
      }
    }

    return stage;
  }

  handleDeleteFromTemplates = (
    event: ScenariosEventType,
    data: ScenarioIsNoLongerTemplateDataType
  ): void => {
    const scenario = this.commonScenarios.getEntity(data.id);
    if (!scenario) {
      return;
    }
    scenario.setIsTemplate(false);
  };

  changeSearchText = (value: string) => {
    this.searchText.changeValue(value);
    this.debouncedSearchScenarios();
  };

  changeScenariosActionsOpened = (value: boolean): void => {
    if (!this.rootStore.projectId) {
      return;
    }

    this.scenariosActionsOpened = value;

    localStorageHandler.setItem(
      createScenariosActionsOpenedKey(this.rootStore.projectId),
      JSON.stringify(value)
    );
  };

  toggleScenariosActionsOpened = (): void => {
    this.changeScenariosActionsOpened(!this.scenariosActionsOpened);
  };

  reset(): void {
    this.unsubscribeAll();
    this.commonScenarios.reset();
    this.reactionScenarios.reset();
  }
}
