import * as React from 'react';

import { CommonDirection } from '../client';

import useEventListener from './useEventListenerHook';

const useResizableWindow = ({
  initialWidth,
  directionOfExpansion
}: {
  initialWidth?: number | string | undefined;
  directionOfExpansion: CommonDirection.right | CommonDirection.left;
}): {
  onMouseDown: () => void;
  isDragging: boolean;
  width: number | string | undefined;
  setWidth: (value: number | string | undefined) => void;
} => {
  const [width, setWidth] = React.useState<number | string | undefined>(
    initialWidth
  );

  const [isDragging, setIsDragging] = React.useState(false);

  const onMouseDown = React.useCallback(() => {
    setIsDragging(true);
  }, []);

  const onMouseUp = React.useCallback(() => {
    setIsDragging(false);
  }, []);

  const onMouseMove = React.useCallback(
    (e: MouseEvent) => {
      if (!isDragging) {
        return;
      }
      setWidth((currentSize) => {
        if (!currentSize || typeof currentSize !== 'number') {
          return;
        }
        return directionOfExpansion === CommonDirection.right
          ? currentSize + e.movementX / 2
          : currentSize - e.movementX / 2;
      });
    },
    [isDragging, directionOfExpansion]
  );

  useEventListener({
    eventName: 'mousemove',
    handler: onMouseMove,
    getElement: () => document
  });

  useEventListener({
    eventName: 'mouseup',
    handler: onMouseUp,
    getElement: () => document
  });
  return {
    onMouseDown,
    isDragging,
    width,
    setWidth
  };
};

export default useResizableWindow;
