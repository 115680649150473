import { makeObservable, computed } from 'mobx';

import {
  AIAggregatedDocType,
  AIDocError,
  AIDocModelParams,
  IAIDoc
} from 'shared/entities/smartbotAI';
import { IRootStore } from 'shared/entities/store/rootStore';
import { generateId } from 'shared/entities/common/utils';

import { LoadingStageModel } from '../loadingStage';
import { FieldModel } from '../form';

export abstract class AIDocModel<
  T extends AIAggregatedDocType,
  I extends string | null = string | null
> implements IAIDoc
{
  readonly rootStore: IRootStore;
  // id для отображения в списке
  readonly renderId: string;
  // реальный id, появляется после загрузки файла
  readonly id: FieldModel<I>;
  readonly url: FieldModel<string | null>;
  readonly cost: FieldModel<number | null>;
  readonly title: string;
  readonly removingStage: LoadingStageModel = new LoadingStageModel();
  readonly errors: FieldModel<AIDocError[] | null>;
  readonly progress: FieldModel<number>;
  readonly type: T;
  readonly botId: string;
  readonly dateCreated: Date;

  constructor({
    rootStore,
    id,
    cost,
    title,
    url,
    errors,
    progress,
    type,
    botId,
    dateCreated
  }: AIDocModelParams<T, I>) {
    this.rootStore = rootStore;
    this.id = new FieldModel<I>(id);
    this.cost = new FieldModel<number>(cost);
    this.title = title;
    this.url = new FieldModel<string | null>(url);
    this.errors = new FieldModel<AIDocError[] | null>(errors);
    this.progress = new FieldModel<number>(progress);
    this.renderId = generateId();
    this.type = type;
    this.botId = botId;
    this.dateCreated = dateCreated;

    makeObservable(this, {
      withErrors: computed
    });
  }

  get withErrors(): boolean {
    return !!this.errors.value?.length;
  }

  get saved(): boolean {
    return this.progress.value === 100 && !this.withErrors;
  }

  abstract remove(): Promise<BaseResponse>;
}
