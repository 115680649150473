import { IField } from 'shared/entities/form';

export interface IAnalytics {
  initialize(): void;
  reset(): void;
  addToQueue(event: AnalyticsEventObj): void;
  initialized: IField<boolean | null>;
}

export interface IYandexAnalytics extends IAnalytics {
  getId(): Promise<BaseResponse<string>>;
}

export interface IRoistatAnalytics extends IAnalytics {
  readonly id: IField<string | null>;
}

export enum AnalyticsEvent {
  authFirstPartRegistrationOk = 'auth_first_part_registration_ok',
  authRegistrationOk = 'auth_registration_ok',
  authRegistrationError = 'auth_registration_error',
  authCabinetCreationOk = 'auth_cabinet_creation_ok',
  authRegistrationPageView = 'auth_registration_page_view',
  authClickVkAuth = 'auth_click_vk_auth',
  authClickELamaAuth = 'auth_click_elama_auth',
  authClickGoogleAuth = 'auth_click_google_auth',
  authClickTgAuth = 'auth_click_tg_auth',
  authNeedExtraData = 'auth_need_extra_data',
  authAddedExtraData = 'auth_added_extra_data',

  scenarioCreationOk = 'scenario_creation_ok',

  channelCreationOk = 'channel_creation_ok',
  channelCreationViaJivoOk = 'channel_creation_via_jivo_ok',
  channelCreationViaWazzupOk = 'channel_creation_via_wazzup_ok',

  templateOpenModal = 'template_open_modal',
  templateApply = 'template_apply',
  templateOpen = 'template_open',
  notFoundDesiredTemplate = 'not_found_desired_template',

  paymentOpenKassa = 'payment_open_kassa',
  paymentSuccess = 'payment_success',
  paymentSuccessYm = 'payment_success_ym',
  paymentSuccessSubscriptionAvailable = 'payment_success_subscription_available',
  paymentSubscriptionActivation = 'payment_subscription_activation',
  paymentBalance = 'payment_balance',
  balancePageView = 'balance_page_view',
  connectBusinessTariff = 'connect_business_tariff',
  carrotquestSendPhone = 'carrotquest_send_phone',

  linkToVkClick = 'link_to_vk_click',
  linkToTgClick = 'link_to_tg_click',
  linkToSmartbotBlogClick = 'link_to_smartbot_blog_click',

  statsOnBlock = 'stats_on_block',

  projectUnitClick = 'project_unit_click',

  shopbackCreateProject = 'shopback_create_project',
  shopbackCreateCategory = 'shopback_create_category',
  shopbackCreateProduct = 'shopback_create_product',

  smartbotAIBotCreate = 'smartbot_ai_bot_create',
  smartbotAIBotUpdate = 'smartbot_ai_bot_update',
  smartbotAIBotTest = 'smartbot_ai_bot_test',
  smartbotAIBotConnectionPageView = 'smartbot_ai_bot_connection_page_view',
  smartbotAIBotInstructionPageView = 'smartbot_ai_bot_instruction_page_view',
  smartbotAIChannelsModalOpen = 'smartbot_ai_channels_modal_open',
  smartbotAIPaymentModalOpen = 'smartbot_ai_payment_modal_open',

  onboardingOpenDocumentation = 'onboarding_open_documentation',
  onboardingOpenSupportChat = 'onboarding_open_support_chat',
  onboardingOpenRequestBot = 'onboarding_open_request_bot',
  onboardingTestBot = 'onboarding_test_bot',
  onboardingFinish = 'onboarding_finish',

  whatsAppExperimentClickPay = 'whatsapp_experiment_click_pay',
  creationChannelOpenForm = 'creation_channel_open_form',
  creationChannelViaJivoOpenForm = 'creation_channel_via_jivo_open_form',
  creationChannelViaWazzupOpenForm = 'creation_channel_via_wazzup_open_form',

  writePersonalManagerClick = 'write_personal_manager_click',
  closePersonalManagerBanner = 'close_personal_manager_banner'
}

export type AnalyticsEventType = AnalyticsEvent | string;

export type AnalyticsPayload = Record<string, any>;

export type AnalyticsEventObj = {
  type: AnalyticsEvent | string;
  payload: AnalyticsPayload;
};
