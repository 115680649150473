import PubSub from 'pubsub-js';
import { action, computed, makeObservable, observable } from 'mobx';
import { matchPath } from 'react-router';

import { IRootStore } from 'shared/entities/store/rootStore';
import { IAppNotificationsStore } from 'shared/entities/store/appNotificationsStore';
import PubSubObserver from 'lib/PubSubObserver';
import {
  AppNotificationOpenParams,
  BannerOpenParams
} from 'shared/entities/appNotifications';
import ListModel from 'shared/models/ListModel';
import { FieldModel } from 'shared/models/form';

import { AppNotificationEvents } from './config';

export default class AppNotificationsStore
  extends PubSubObserver
  implements IAppNotificationsStore
{
  readonly banners: ListModel<BannerOpenParams> =
    new ListModel<BannerOpenParams>();

  rootStore: IRootStore;

  initialized = false;

  readonly forbiddenErrorOpened: FieldModel<boolean> = new FieldModel<boolean>(
    false
  );

  constructor(rootStore: IRootStore) {
    super();

    this.rootStore = rootStore;

    makeObservable(this, {
      initialized: observable,

      bannerOpened: computed,
      banner: computed,

      openBanner: action,
      closeBanner: action.bound,
      setInitialized: action
    });
  }

  setInitialized = (value: boolean): void => {
    this.initialized = value;
  };

  get banner(): BannerOpenParams | null {
    if (!this.banners.length) {
      return null;
    }

    const last = this.banners.items.length - 1;

    return this.banners.items[last];
  }

  get bannerOpened(): boolean {
    return (
      !!this.banner &&
      !(this.banner.excludePath || []).some((path) =>
        matchPath(this.rootStore.routerStore.pathname, path)
      )
    );
  }

  open(params: AppNotificationOpenParams): void {
    PubSub.publish(AppNotificationEvents.open, params);
  }

  openBanner(banner: BannerOpenParams): void {
    this.banners.addEntity({
      entity: banner,
      key: banner.id
    });
  }

  openForbiddenError(params: AppNotificationOpenParams): void {
    if (this.forbiddenErrorOpened.value) {
      return;
    }
    this.forbiddenErrorOpened.changeValue(true);
    PubSub.publish(AppNotificationEvents.openForbiddenError, params);
  }

  setForbiddenErrorClosed = (): void => {
    this.forbiddenErrorOpened.changeValue(false);
  };

  closeOpenedBanner = (): void => {
    if (!this.banner) {
      return;
    }

    this.banner.onClose?.();
    this.banners.removeEntity(this.banner.id);
  };

  closeBanner(bannerId: string): void {
    const banner = this.banners.getEntity(bannerId);

    if (!banner) {
      return;
    }

    this.banners.removeEntity(banner.id);
  }
}
