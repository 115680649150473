import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const PinnedIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.L}
      {...props}
      svgProps={{ viewBox: '0 0 24 24', ...(props.svgProps || {}) }}
    >
      <path
        d="M14.748 0.899785C14.352 1.01978 14.004 1.28378 13.476 1.83578C12.636 2.71178 12.468 3.17978 12.756 3.86378L12.936 4.29578L11.028 6.17979L9.13197 8.07579L8.37597 7.96779C6.37197 7.69179 5.42397 8.00379 3.94797 9.44379C3.25197 10.1158 2.99997 10.4398 2.99997 10.6678C2.99997 10.9078 3.50397 11.4718 5.24397 13.2238L7.49997 15.4798L4.10397 18.8758C2.24397 20.7358 0.719971 22.3678 0.719971 22.4758C0.719971 22.7518 1.24797 23.2798 1.52397 23.2798C1.63197 23.2798 3.25197 21.7558 5.12397 19.8958L8.51997 16.5118L10.776 18.7558C12.504 20.4718 13.104 20.9998 13.332 20.9998C13.704 20.9998 15.312 19.4038 15.696 18.6598C16.056 17.9278 16.212 16.9318 16.08 16.1158C15.876 14.8678 15.804 14.9878 17.832 12.9478L19.704 11.0638L20.148 11.2438C20.88 11.5318 21.432 11.3038 22.296 10.3918C23.304 9.29979 23.508 8.37579 22.956 7.25979C22.704 6.74379 17.316 1.35578 16.776 1.06778C16.164 0.755785 15.408 0.683785 14.748 0.899785ZM18.864 5.13578C21.528 7.79979 21.72 8.01579 21.72 8.41179C21.72 8.74779 21.612 8.97579 21.252 9.37179L20.784 9.89979L20.424 9.68379C20.22 9.57579 19.884 9.47979 19.668 9.47979C19.32 9.47979 19.032 9.71979 17.016 11.7238C14.592 14.1478 14.304 14.5558 14.448 15.4438C14.676 16.9318 14.676 17.2318 14.436 17.7718C14.304 18.0718 14.016 18.5158 13.8 18.7678L13.404 19.2238L9.10797 14.9278L4.79997 10.6318L5.12397 10.2838C5.30397 10.1038 5.73597 9.81579 6.09597 9.63579C6.75597 9.31179 6.95997 9.31179 8.55597 9.55179C9.44397 9.69579 9.85197 9.40779 12.276 6.98379C14.28 4.96778 14.52 4.67978 14.52 4.33178C14.52 4.11578 14.424 3.77978 14.316 3.57578L14.1 3.21578L14.628 2.74778C15.024 2.38778 15.252 2.27978 15.588 2.27978C15.984 2.27978 16.2 2.47178 18.864 5.13578Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default PinnedIcon;
