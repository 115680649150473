import { action, makeObservable, observable, runInAction } from 'mobx';

import {
  IManagerBase,
  mapManagerRemovingErrorCodeToMessage
} from 'shared/entities/manager';
import { IBaseManagersStore } from 'shared/entities/store/managersStore';
import { LoadingStage } from 'shared/entities/meta';
import { apiUrls } from 'shared/entities/domain';
import { IRootStore } from 'shared/entities/store/rootStore';

export default class BaseManagerModel<T> implements IManagerBase {
  name: string;
  email: string;

  managersStore: IBaseManagersStore<T>;
  rootStore: IRootStore;

  removingStage: LoadingStage = LoadingStage.NOT_STARTED;
  changingRoleStage: LoadingStage = LoadingStage.NOT_STARTED;

  constructor({
    name,
    managersStore,
    rootStore,
    email
  }: IManagerBase & {
    managersStore: IBaseManagersStore<T>;
    rootStore: IRootStore;
  }) {
    this.name = name;
    this.email = email;
    this.managersStore = managersStore;
    this.rootStore = rootStore;

    makeObservable(this, {
      removingStage: observable,
      changingRoleStage: observable,
      remove: action.bound
    });
  }

  async remove(): Promise<void> {
    if (this.removingStage === LoadingStage.LOADING) {
      return;
    }

    this.removingStage = LoadingStage.LOADING;

    const { isError } = await this.rootStore.networkStore.api(
      apiUrls.MANAGERS_REMOVE,
      {
        method: 'POST',
        data: {
          email: this.email
        },

        errorsMap: mapManagerRemovingErrorCodeToMessage
      }
    );

    runInAction(() => {
      if (!isError) {
        this.removingStage = LoadingStage.SUCCESS;
        this.managersStore.remove({ email: this.email });
      } else {
        this.removingStage = LoadingStage.ERROR;
      }
    });
  }
}
