import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import {
  apiUrls,
  AuthStage,
  getUrlFromPathAndParamsObject,
  urls
} from 'shared/entities/domain';
import { IRootStore } from 'shared/entities/store/rootStore';
import { AnalyticsEvent } from 'shared/entities/analytics';
import { LoadingStage } from 'shared/entities/meta';
import { AppNotificationType } from 'shared/entities/appNotifications';
import { TgAuthFuncData } from 'shared/entities/tgAuth';
import { ChannelKind, channelKindEntities } from 'shared/entities/channels';
import {
  LoginErrors,
  mapRegistrationErrorToMessage,
  RegistrationErrors,
  AuthorizationStages,
  mapBaseLoginAuthErrors,
  OAuthCommonError,
  BaseAuthLoginErrors,
  mapOAuthTgErrorsToMessage,
  OAuthTgErrors,
  mapLoginErrorToMessage,
  IOauthInfo,
  normalizeOauthInfo,
  OauthInfoServer
} from 'shared/entities/user';
import { LoadingStageModel } from 'shared/models/loadingStage';
import CabinetCreationStore from 'stores/cabinetCreationStore/CabinetCreationStore';
import { FieldModel } from 'shared/models/form';
import UserEditModel from 'shared/models/users/UserEditModel';
import SmartCaptchaModel from 'shared/models/captcha';

const BANNER_ID = '__TG_AUTH_BANNER__';

export default class AuthStore {
  rootStore: IRootStore;
  cabinetCreationStore: CabinetCreationStore;

  authorizationStage: AuthorizationStages = AuthorizationStages.LOGIN;
  requestTgAuthStage: LoadingStage = LoadingStage.NOT_STARTED;
  readonly registerStage: LoadingStageModel = new LoadingStageModel();

  readonly registered: FieldModel<boolean> = new FieldModel<boolean>(false);
  readonly requestOauthLinksStage: LoadingStageModel = new LoadingStageModel();
  readonly oauthInfo: FieldModel<IOauthInfo | null> =
    new FieldModel<IOauthInfo | null>(null);
  readonly editModel: UserEditModel = new UserEditModel();
  readonly registerCaptcha: SmartCaptchaModel = new SmartCaptchaModel();

  constructor({ rootStore }: { rootStore: IRootStore }) {
    this.rootStore = rootStore;
    this.cabinetCreationStore = new CabinetCreationStore({
      rootStore,
      newUser: true
    });

    makeObservable(this, {
      authorizationStage: observable,
      requestTgAuthStage: observable,

      requestTgAuth: action,
      handleTgAuthResponse: action,
      login: action.bound,
      changeStageToRegistration: action.bound,
      changeStageToLogin: action.bound,
      register: action.bound,

      registeredButtonDisabled: computed,
      isError: computed
    });
  }

  get isError(): boolean {
    return this.editModel.isError;
  }

  get registeredButtonDisabled(): boolean {
    return (
      (!this.registered.value && this.isError) ||
      this.cabinetCreationStore.cabinetEditModel.isError
    );
  }

  requestOauthLinks = async (): Promise<BaseResponse> => {
    if (this.requestOauthLinksStage.isLoading) {
      return {
        isError: true
      };
    }

    this.requestOauthLinksStage.loading();

    const response = await this.rootStore.networkStore.api<OauthInfoServer>(
      apiUrls.OAUTH_LIST_LOGIN_URLS
    );

    if (response.isError) {
      this.requestOauthLinksStage.error();
    } else {
      this.oauthInfo.changeValue(normalizeOauthInfo(response.data));
      this.requestOauthLinksStage.success();
    }

    return response;
  };

  changeStageToRegistration(): void {
    this.authorizationStage = AuthorizationStages.REGISTRATION;
    this.resetErrors();
  }

  changeStageToLogin(): void {
    this.authorizationStage = AuthorizationStages.LOGIN;
    if (this.registered.value) {
      this.reset();
      this.registered.reset();
      this.cabinetCreationStore.cabinetEditModel.reset();
    }
  }

  handleTgAuthResponse = async (data: TgAuthFuncData): Promise<void> => {
    this.rootStore.appNotificationsStore.closeBanner(BANNER_ID);

    if (!data) {
      this.requestTgAuthStage = LoadingStage.ERROR;
      this.rootStore.appNotificationsStore.open({
        type: AppNotificationType.error,
        title: (t) =>
          t('AuthStore.notifications.authError', {
            ns: 'stores',
            channelName: channelKindEntities[ChannelKind.TELEGRAM].title
          })
      });

      return;
    }

    const response = await this.rootStore.networkStore.api<
      {},
      OAuthTgErrors,
      {
        manager_id: string;
        codes: OAuthCommonError[];
      }
    >(apiUrls.OAUTH_TG_LOGIN_REDIRECT, {
      method: 'POST',
      data,
      errorsMap: mapOAuthTgErrorsToMessage,
      showExpectedError: false
    });

    if (response.isError) {
      runInAction(() => {
        this.requestTgAuthStage = LoadingStage.ERROR;
      });

      if (response.data?.data.codes && response.data?.data.manager_id) {
        this.rootStore.routerStore.push(
          getUrlFromPathAndParamsObject(urls.AUTH.create(AuthStage.ERROR), {
            manager_id: response.data.data.manager_id,
            error: response.data.data.codes
          })
        );
      }
    } else {
      runInAction(() => {
        this.requestTgAuthStage = LoadingStage.SUCCESS;
      });
      this.rootStore.authFlowStore.authorize();
    }
  };

  requestTgAuth = async (): Promise<void> => {
    if (!this.oauthInfo.value || this.oauthInfo.value.tgBotId === null) {
      return;
    }

    this.requestTgAuthStage = LoadingStage.LOADING;

    this.rootStore.analyticsStore.sendEvent(AnalyticsEvent.authClickTgAuth);

    try {
      window.Telegram.Login.auth(
        {
          bot_id: this.oauthInfo.value.tgBotId,
          request_access: 'write',
          lang: 'ru'
        },
        this.handleTgAuthResponse
      );
      this.rootStore.appNotificationsStore.openBanner({
        id: BANNER_ID,
        type: AppNotificationType.warning,
        title: (t) =>
          t('AuthStore.notifications.windows', {
            ns: 'stores',
            channelName: channelKindEntities[ChannelKind.TELEGRAM].title
          })
      });
    } catch (e) {
      runInAction(() => {
        this.requestTgAuthStage = LoadingStage.ERROR;
      });
      // eslint-disable-next-line no-console
      console.log('requestTgAuth error', e);
    }
  };

  executeCaptcha = (): void => {
    const isError = this.validateBeforeRegister();
    if (isError) {
      return;
    }
    this.registerCaptcha.execute();
  };

  validateBeforeRegister = (): boolean => {
    const isCabinetError =
      this.cabinetCreationStore.cabinetEditModel.validate();

    if (!isCabinetError && !this.registered.value) {
      return this.validate();
    }
    return !!isCabinetError;
  };

  async login(): Promise<void> {
    const isEmailError = this.editModel.email.validate();
    const isPasswordError = this.editModel.password.validate();
    const isTokenError = this.editModel.captcha.failed;

    if (isEmailError || isPasswordError || isTokenError) {
      return;
    }

    const response = await this.rootStore.userStore.login({
      email: this.editModel.email.value,
      password: this.editModel.password.value,
      captcha_token: this.editModel.captcha.token.value
    });

    this.editModel.resetCaptcha();

    if (response.isError && response.data) {
      if (Object.values(LoginErrors).includes(response.data as LoginErrors)) {
        if (response.data === LoginErrors.MANAGER_NOT_FOUND) {
          this.changeStageToRegistration();
          this.rootStore.analyticsStore.sendEvent(
            AnalyticsEvent.authFirstPartRegistrationOk,
            {
              user_email: this.editModel.email.value
            }
          );
          return;
        }

        const errorText = mapLoginErrorToMessage(response.data as LoginErrors);

        if (errorText) {
          this.editModel.email.setError(errorText);
          this.editModel.password.setError(errorText);
        }
      }

      if (
        Object.values(BaseAuthLoginErrors).includes(
          response.data as BaseAuthLoginErrors
        )
      ) {
        this.rootStore.appNotificationsStore.open({
          type: AppNotificationType.error,
          title: mapBaseLoginAuthErrors(response.data as BaseAuthLoginErrors)
        });
      }

      return;
    }

    this.rootStore.authFlowStore.redirectToAvailableCabinetFromAuthDomain();
  }

  async register(): Promise<BaseResponse> {
    if (this.registerStage.isLoading) {
      return {
        isError: true
      };
    }

    const isCabinetError =
      this.cabinetCreationStore.cabinetEditModel.validate();

    if (isCabinetError) {
      return {
        isError: true
      };
    }

    if (this.registerCaptcha.failed) {
      return {
        isError: true
      };
    }

    this.registerStage.loading();

    if (!this.registered.value) {
      const isError = this.validate();

      if (isError) {
        this.registerStage.error();

        return {
          isError: true
        };
      }

      const response = await this.rootStore.userStore.register({
        email: this.editModel.email.value,
        password: this.editModel.password.value,
        name: this.editModel.name.value,
        captcha_token: this.registerCaptcha.token.value
      });

      if (response.isError) {
        if (response.data === RegistrationErrors.EMAIL_TAKEN) {
          this.editModel.email.setError(
            mapRegistrationErrorToMessage(response.data)
          );
        }

        this.rootStore.analyticsStore.sendEvent(
          AnalyticsEvent.authRegistrationError,
          {
            registration_error: response.data
          }
        );

        this.registerStage.error();
        this.registerCaptcha.reset();

        return {
          isError: true
        };
      }

      this.rootStore.analyticsStore.sendEvent(
        AnalyticsEvent.authRegistrationOk
      );
      this.registered.changeValue(true);
      this.registerCaptcha.reset();
    }

    const { isError } = await this.cabinetCreationStore.createInitialData();

    if (isError) {
      this.registerStage.error();

      return {
        isError: true
      };
    }

    this.registerStage.success();

    return {
      isError: false
    };
  }

  private reset(): void {
    this.editModel.reset();
    this.cabinetCreationStore.cabinetEditModel.reset();
  }

  private resetErrors(): void {
    this.editModel.resetErrors();
    this.cabinetCreationStore.cabinetEditModel.resetErrors();
  }

  private validate(): boolean {
    this.editModel.validate();
    this.cabinetCreationStore.cabinetEditModel.validate();

    return this.isError;
  }
}
