import { isFuture } from 'date-fns';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { TFunctionType, TranslationString } from 'shared/entities/localization';

import { convertStringToNumber, getDomainNameFromUrl } from '../common/utils';
import {
  REG_EXP_URL_WITH_OPTIONAL_PROTOCOL_WORD,
  REG_EXP_URL_WITH_REQUIRED_PROTOCOL_WORD
} from '../regExp/url';
import { REG_EXP_NUMBER } from '../regExp/number';
import { VAR_BODY_REG_EXP_WORD } from '../regExp/var';

import { Validator, ValidatorResult } from './client';

export const validatorTransFunctions = {
  requiredField: (t: TFunctionType): string =>
    t('validators.errors.requiredField', {
      ns: 'entities'
    })
};

export const isNumber = (value: string): boolean => {
  return REG_EXP_NUMBER.test(value);
};

export const validateEmail = (email: string): boolean => {
  const arr = email.split('@');
  if (arr.length < 2) {
    return false;
  }

  const isNameCorrect =
    /(^[-!#$%&'*+/=?^`{}|~\w]+(\.[-!#$%&'*+/=?^`{}|~\w]+)*|^"([\u{001}-\u{010}\u{013}\u{014}\u{016}-\u{037}!#-[\]-\u{177}]|\\[\u{001}-\u{011}\u{013}\u{014}\u{016}-\u{177}])*")/iu.test(
      arr[0]
    );

  const isDomainCorrect =
    /(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}|[A-Z0-9-]{2,})|^\[(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}\]/iu.test(
      arr[1]
    );

  return isNameCorrect && isDomainCorrect;
};

const validateDomain = (domain: string): boolean => {
  return /^[a-z][a-z0-9\-_]+[a-z0-9]$/.test(domain);
};

export const maxLengthErrorText = (t) =>
  t('validators.errors.maxLengthField', {
    ns: 'entities',
    maxLength: MAX_TEXT_LENGTH
  });

export const validateTextFieldMaxLength = (
  value: string,
  maxLength = MAX_TEXT_LENGTH
): ValidatorResult => {
  return value.trim().length > maxLength
    ? (t) =>
        t('validators.errors.maxLengthField', {
          ns: 'entities',
          maxLength
        })
    : null;
};

export const validateTextField: Validator<string | null> = (value) => {
  const emptyError = validateIsEmpty(value);

  if (value === null || emptyError) {
    return emptyError;
  }

  return validateTextFieldMaxLength(value);
};

export const validateNameField: Validator<string> = (value) => {
  const trimmedValue = value.trim();

  if (trimmedValue.length < 1) {
    return (t) =>
      t('validators.errors.minNameLengthField', {
        ns: 'entities'
      });
  }
  return trimmedValue.length > MAX_NAME_LENGTH
    ? (t) =>
        t('validators.errors.maxNameLengthField', {
          ns: 'entities'
        })
    : null;
};

export const validateTextFieldLength =
  ({ min, max }: { min: number; max: number }): Validator<string> =>
  (value: string) => {
    return value.length <= max && value.length >= min
      ? null
      : (t) =>
          t('validators.errors.invalidLength', {
            max,
            min,
            ns: 'entities'
          });
  };

export const validateTemplate: Validator<string> = (value) => {
  const REG_EX = /<[^<>]+>/gm;

  if (value.match(REG_EX) === null) {
    return (t) =>
      t('validators.errors.invalidTemplate', {
        ns: 'entities'
      });
  }

  return null;
};

export const validateGoogleSheetCell: Validator<string> = (cellId) => {
  if (!cellId) {
    return (t) =>
      t('validators.errors.emptyCellId', {
        ns: 'entities'
      });
  }

  const REG_EX = /^[A-Z]+[1-9]\d*$/gm;

  if (!REG_EX.test(cellId)) {
    return (t) =>
      t('validators.errors.invalidCellId', {
        ns: 'entities'
      });
  }

  return null;
};

export const validateGoogleSheetRangeCell: Validator<string> = (cellId) => {
  if (!cellId) {
    return (t) =>
      t('validators.errors.emptyCellId', {
        ns: 'entities'
      });
  }

  const REG_EX = /^[A-Z]+(\d+)?$/gm;

  if (cellId.match(REG_EX) === null) {
    return (t) =>
      t('validators.errors.invalidCellId', {
        ns: 'entities'
      });
  }

  return null;
};

export const validateGoogleSheetRange: Validator<string> = (range) => {
  const REG_EX = /^(?:[A-Z]+[1-9]\d*:[A-Z]+[1-9]\d*|[A-Z]+:[A-Z]+)$/gm;

  if (range.match(REG_EX) === null) {
    return (t) =>
      t('validators.errors.invalidGoogleRange', {
        ns: 'entities'
      });
  }

  return null;
};

export const validateIsEmpty: Validator<any> = (value) => {
  if (typeof value === 'string') {
    return !value.length ? validatorTransFunctions.requiredField : null;
  }
  if (typeof value === 'number') {
    return null;
  }

  if (Array.isArray(value)) {
    return !value.length ? validatorTransFunctions.requiredField : null;
  }

  return value ? null : validatorTransFunctions.requiredField;
};

export const validateEmailField: Validator<string> = (value) => {
  const str = value.trim();
  return !validateEmail(str)
    ? (t) =>
        t('validators.errors.invalidEmail', {
          ns: 'entities'
        })
    : null;
};

export const validateDomainField: Validator<string> = (value) => {
  return !validateDomain(value) || !(value.length > 6 && value.length < 24)
    ? (t) =>
        t('validators.errors.invalidDomain', {
          ns: 'entities'
        })
    : null;
};

export const validateInterval = (value, min: number, max: number) => {
  const number = convertStringToNumber(value);
  return number < min || number > max
    ? (t) =>
        t('validators.errors.invalidNumberInterval', {
          ns: 'entities',
          min,
          max
        })
    : null;
};

export const validateIntervalInSeconds = (
  value: number,
  min: number,
  max: number
) => {
  return value < min || value > max
    ? (t) =>
        t('validators.errors.invalidDateInterval', {
          ns: 'entities'
        })
    : null;
};

export const validateTimeoutInterval = ({
  value,
  min,
  max
}: {
  value: number;
  min?: number;
  max?: number;
}) => {
  if (min === undefined && max === undefined) {
    return null;
  }

  if (min !== undefined && max !== undefined) {
    return validateInterval(value, min, max);
  }
  if (max !== undefined) {
    return value > max
      ? (t) =>
          t('validators.errors.max', {
            ns: 'entities',
            max
          })
      : null;
  }

  if (min !== undefined) {
    return value < min
      ? (t) =>
          t('validators.errors.min', {
            ns: 'entities',
            min
          })
      : null;
  }
  return null;
};

export const validateVarKey: Validator<string> = (value) => {
  return VAR_BODY_REG_EXP_WORD.test(value)
    ? null
    : (t) =>
        t('validators.errors.invalidVarKey', {
          ns: 'entities'
        });
};

export const validateVarKeyWithPercent: Validator<string | null> = (value) => {
  if (!value) {
    return null;
  }
  const regex = /^%[^%]{1,61}%$/;

  return regex.test(value)
    ? null
    : (t) =>
        t('validators.errors.invalidVarWithPercentKey', {
          ns: 'entities'
        });
};

export const validateNumberValue: Validator<string> = (value) => {
  return isNumber(value)
    ? null
    : (t) =>
        t('validators.errors.invalidNumberValue', {
          ns: 'entities'
        });
};

export const validateChoiceWeightValue: Validator<string> = (value: string) => {
  if (!isNumber(value)) {
    return (t) =>
      t('validators.errors.invalidNumberValue', {
        ns: 'entities'
      });
  }

  const number = convertStringToNumber(value);
  return number >= 0 && number <= 100
    ? null
    : (t) =>
        t('validators.errors.invalidChoiceWeight', {
          ns: 'entities'
        });
};

export const validateJsonValue = (
  value: string
): {
  validatorResult: ValidatorResult;
  result: any;
} => {
  try {
    const parsed = JSON.parse(value);
    return {
      validatorResult: null,
      result: parsed
    };
  } catch (e) {
    return {
      validatorResult: (t) =>
        t('validators.errors.invalidJsonValue', {
          ns: 'entities'
        }),
      result: null
    };
  }
};

export const validateArrayJsonValue: Validator<string> = (value) => {
  const { validatorResult, result } = validateJsonValue(value);

  if (validatorResult) {
    return validatorResult;
  }

  return Array.isArray(result)
    ? null
    : (t) =>
        t('validators.errors.invalidArrayJsonValue', {
          ns: 'entities'
        });
};

export const validateMapJsonValue: Validator<string> = (value) => {
  const { validatorResult, result } = validateJsonValue(value);

  if (validatorResult) {
    return validatorResult;
  }

  return typeof result === 'object' && result !== null && !Array.isArray(result)
    ? null
    : (t) =>
        t('validators.errors.invalidMapJsonValue', {
          ns: 'entities'
        });
};

const MIN_NAME_LENGTH = 3;
export const MAX_NAME_LENGTH = 256;
export const MAX_TEXT_LENGTH = 4096;
const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_LENGTH = 256;

export const validateName = validateTextFieldLength({
  min: MIN_NAME_LENGTH,
  max: MAX_NAME_LENGTH
});

export const validatePassword = validateTextFieldLength({
  min: MIN_PASSWORD_LENGTH,
  max: MAX_PASSWORD_LENGTH
});

export const validateTime = (time: string | null): ValidatorResult => {
  if (!time) {
    return validatorTransFunctions.requiredField;
  }

  const TIME_REGEXP = /(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/;

  return TIME_REGEXP.test(time)
    ? null
    : (t) =>
        t('validators.errors.invalidTimeValue', {
          ns: 'entities'
        });
};

export const validateUrl = (url: string): ValidatorResult => {
  return REG_EXP_URL_WITH_REQUIRED_PROTOCOL_WORD.test(url)
    ? null
    : (t) =>
        t('validators.errors.invalidUrlValue', {
          ns: 'entities'
        });
};

export const validateUrlWithOptionalProtocol = (
  url: string
): ValidatorResult => {
  const trimmedUrl = url.trim();
  const domain = getDomainNameFromUrl(trimmedUrl) || trimmedUrl;

  return REG_EXP_URL_WITH_OPTIONAL_PROTOCOL_WORD.test(trimmedUrl) &&
    domain.length < MAX_NAME_LENGTH
    ? null
    : (t) =>
        t('validators.errors.invalidUrlValue', {
          ns: 'entities'
        });
};

export const validateDomainLengthsInUrl = (url: string) => {
  const REG_EXP = /([-a-zA-Z0-9а-яА-Яё]+\.)/g;
  const domains: string[] | null = url.match(REG_EXP);

  if (!domains || domains.every((domain) => domain.length <= 64)) {
    return null;
  }

  return (t) =>
    t('validators.errors.invalidDomainLengthsInUrl', {
      ns: 'entities'
    });
};

const validateAttachmentsNumber = (
  value: string,
  minCount: number
): ValidatorResult => {
  const emptyError = validateIsEmpty(value);
  if (emptyError) {
    return validatorTransFunctions.requiredField;
  }

  const number = convertStringToNumber(value);
  return number < minCount
    ? (t) =>
        t('validators.errors.invalidAttachmentsCount', {
          ns: 'entities'
        })
    : null;
};

export const validateAttachmentsCount: Validator<string> = (value) => {
  return validateAttachmentsNumber(value, 1);
};

export const validateAttachmentsMinCount: Validator<string> = (value) => {
  return validateAttachmentsNumber(value, 0);
};

export const validateUuidv4: Validator<string> = (value) => {
  const REG_EXP =
    /^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}$/;

  return REG_EXP.test(value)
    ? null
    : (t) =>
        t('validators.errors.invalidUuidValue', {
          ns: 'entities'
        });
};

export const validateNumberInterval = (
  min: number,
  max: number,
  errorLabel: TranslationString = (t) =>
    t('validators.errors.invalidInterval', {
      ns: 'entities'
    })
): ValidatorResult => {
  return max < min ? errorLabel : null;
};

export const validateTgCommandField: Validator<string> = (value) => {
  const REG_EXP = /^[0-9a-z_]{1,32}$/;

  return REG_EXP.test(value)
    ? null
    : (t) =>
        t('validators.errors.invalidTgCommand', {
          ns: 'entities'
        });
};

export const validateFutureDate: Validator<Date> = (value) => {
  return isFuture(value)
    ? null
    : (t) =>
        t('validators.errors.invalidDateValue', {
          ns: 'entities'
        });
};

export const validateLatin: Validator<string> = (value) => {
  const REG_EXP = /^[^а-яА-Яё]*$/;

  return REG_EXP.test(value)
    ? null
    : (t) =>
        t('validators.errors.invalidLatin', {
          ns: 'entities'
        });
};

export const validateVkUploadPhotoUrl: Validator<string> = (value) => {
  const REG_EXP = /photo-?\d+_\d+/;

  return REG_EXP.test(value)
    ? null
    : (t) =>
        t('validators.errors.invalidUploadVkPhotoUrl', {
          ns: 'entities'
        });
};

export const validatePhoneNumber: Validator<string> = (value) => {
  return isValidPhoneNumber(value)
    ? null
    : (t) =>
        t('validators.errors.invalidPhoneNumber', {
          ns: 'entities'
        });
};

export const validateNumberInInterval = ({
  value,
  max,
  min
}: {
  value: number;
  min: number;
  max: number;
}): boolean => {
  return value >= min && value <= max;
};
