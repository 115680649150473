import { action, computed, makeObservable, observable, reaction } from 'mobx';
import isMobile from 'ismobilejs';

import { IUiStore } from 'shared/entities/store/uiStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import {
  calculateWindowSize,
  mapSizeToLimit,
  WindowSize
} from 'shared/entities/windowSize';
import { ReactionsHandlerStore } from 'stores/reactionsHandlerStore';
import { AreaSize } from 'shared/entities/geometry';
import OpenStateModel from 'shared/models/openState/OpenStateModel';
import { localStorageHandler } from 'stores/localStorageHandler';
import { LocalStorageKey } from 'shared/entities/localStorage';
import getBooleanValueFromLS from 'shared/utils/getBooleanValueFromLS';

import { ModalsStore } from './ModalsStore';

const DAYS_LEFT_TO_SHOW_CABINET = 3;

export default class UiStore extends ReactionsHandlerStore implements IUiStore {
  rootStore: IRootStore;
  currentSize: WindowSize = WindowSize.xxs;
  private _pxSize: AreaSize = {
    width: 0,
    height: 0
  };
  isAuthorizedModalOpen = false;
  isMobileDevice = false;
  isAppleDevice = false;

  readonly modalsStore: ModalsStore = new ModalsStore();

  readonly menuState: OpenStateModel;
  cabinetInMenuOpened: boolean;

  constructor(rootStore: IRootStore) {
    super();
    this.rootStore = rootStore;
    this.changeSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
    this.menuState = new OpenStateModel(
      this.isMobileSize
        ? false
        : getBooleanValueFromLS(LocalStorageKey.menuOpened) ?? true
    );
    this.cabinetInMenuOpened =
      getBooleanValueFromLS(LocalStorageKey.cabinetInMenuOpened) ?? true;

    makeObservable<UiStore, '_pxSize'>(this, {
      rootStore: observable,
      isAuthorizedModalOpen: observable,
      currentSize: observable,
      isMobileDevice: observable,
      isAppleDevice: observable,
      _pxSize: observable.ref,
      cabinetInMenuOpened: observable,

      isCommonDesktopSize: computed,
      isMobileSize: computed,
      isSmallWindow: computed,
      smallOrMobileDevice: computed,
      currentSizePx: computed,
      isLargeDesktopSize: computed,
      isMobile: computed,
      isTabletProSize: computed,
      isSmallContentSize: computed,
      isDeskTopMDSize: computed,
      isMediumContentSize: computed,
      isLargeContentSize: computed,
      pxSize: computed,

      changeSize: action,
      closeAuthorizedModal: action,
      openAuthorizedModal: action,
      checkMobileDevice: action,
      changeCabinetInMenuOpened: action
    });
  }

  initReactions = (): void => {
    this.addReaction({
      key: 'shouldPay',
      reaction: reaction(
        () => this.rootStore.paymentStore.info,
        (info) => {
          if (info === null) {
            return;
          }

          // показываем информацию о кабинете, если осталось <= 3 дней триала или если баланса хватит на <=3 дня
          if (
            (!info.isTrialOver &&
              info.trialDaysLeft <= DAYS_LEFT_TO_SHOW_CABINET) ||
            info.daysLeft <= DAYS_LEFT_TO_SHOW_CABINET
          ) {
            this.changeCabinetInMenuOpened(true);
            if (!this.isMobileSize) {
              this.changeMenuOpened(true);
            }
          }
        }
      )
    });
  };

  get pxSize(): AreaSize {
    return this._pxSize;
  }

  closeAuthorizedModal = () => {
    this.isAuthorizedModalOpen = false;
  };

  openAuthorizedModal = () => {
    this.isAuthorizedModalOpen = true;
  };

  get currentSizePx(): number {
    return mapSizeToLimit[this.currentSize];
  }

  changeSize = (size: AreaSize): void => {
    this._pxSize = size;
    this.currentSize = calculateWindowSize(size.width);
  };

  get isTabletProSize(): boolean {
    return this._pxSize.width <= 1024;
  }

  get isDeskTopMDSize(): boolean {
    return this._pxSize.width <= 1440;
  }

  get isMobileSize(): boolean {
    return [WindowSize.xxs].includes(this.currentSize);
  }

  get isSmallWindow(): boolean {
    return [WindowSize.xxs, WindowSize.xs].includes(this.currentSize);
  }

  get isCommonDesktopSize(): boolean {
    return [WindowSize.xs, WindowSize.s, WindowSize.m].includes(
      this.currentSize
    );
  }

  get isLargeDesktopSize(): boolean {
    return [WindowSize.l].includes(this.currentSize);
  }

  get smallOrMobileDevice(): boolean {
    return this.isMobileDevice || this.isSmallWindow;
  }

  get isMobile(): boolean {
    return this.isMobileDevice || this.isMobileSize;
  }

  checkMobileDevice = (): void => {
    this.isMobileDevice = isMobile(window.navigator).any;
    this.isAppleDevice = isMobile(window.navigator).apple.device;
  };

  get isSmallContentSize() {
    return this.menuState.opened ? this.isTabletProSize : this.isMobileSize;
  }

  get isMediumContentSize() {
    return this.menuState.opened ? this.isSmallWindow : this.isTabletProSize;
  }

  get isLargeContentSize() {
    return this.menuState.opened ? this.isDeskTopMDSize : this.isTabletProSize;
  }

  toggleMenu = (): void => {
    this.changeMenuOpened(!this.menuState.opened);
  };

  changeMenuOpened = (value: boolean) => {
    if (value) {
      this.menuState.open();
    } else {
      this.menuState.close();
    }
    localStorageHandler.setItem(
      LocalStorageKey.menuOpened,
      JSON.stringify(value)
    );
  };

  toggleCabinetInMenuOpened = (): void => {
    this.changeCabinetInMenuOpened(!this.cabinetInMenuOpened);
  };

  changeCabinetInMenuOpened = (value: boolean): void => {
    this.cabinetInMenuOpened = value;

    localStorageHandler.setItem(
      LocalStorageKey.cabinetInMenuOpened,
      JSON.stringify(value)
    );
  };
}
