import { action, makeObservable, observable, runInAction } from 'mobx';

import { IListsStore } from 'shared/entities/store/listsStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import ListModel from 'shared/models/ListModel';
import { LoadingStage } from 'shared/entities/meta';
import { apiUrls } from 'shared/entities/domain';
import {
  ListEntityServer,
  ListEntityType,
  listEntityTypesOrder,
  mapListsCreateErrorCodeToMessage
} from 'shared/entities/lists';
import { ListEntityModel } from 'shared/models/lists';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';

export default class ListsStore
  extends ComponentLoadingStore
  implements IListsStore
{
  lists: Map<ListEntityType, ListModel<ListEntityModel>>;

  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    super();

    this.rootStore = rootStore;
    this.lists = new Map<ListEntityType, ListModel<ListEntityModel>>(
      listEntityTypesOrder.map((listType) => [
        listType,
        new ListModel<ListEntityModel>()
      ])
    );

    makeObservable(this, {
      lists: observable,

      loadList: action
    });
  }

  get loaded(): boolean {
    return Array.from(this.lists.values()).every(
      (list) => list.loadingStage === LoadingStage.SUCCESS
    );
  }

  get canBeLoaded(): boolean {
    return this.rootStore.initialized && this.rootStore.userStore.isAuthorized;
  }

  getList({ lType }: { lType: ListEntityType }): ListModel<ListEntityModel> {
    const list = this.lists.get(lType);

    if (list) {
      return list;
    }
    const newList = new ListModel<ListEntityModel>();
    this.lists.set(ListEntityType.users, newList);

    return newList;
  }

  async loadList({ lType }: { lType: ListEntityType }): Promise<BaseResponse> {
    const list = this.lists.get(lType);

    if (!list || list.loadingStage === LoadingStage.LOADING) {
      return {
        isError: true
      };
    }

    list.setLoadingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<{
      lists: ListEntityServer[];
    }>(apiUrls.LISTS_LIST, {
      method: 'GET',
      data: {
        type: lType
      }
    });

    if (!response.isError) {
      const { entities, keys } = response.data.lists.reduce(
        (acc, list) => ({
          ...acc,
          entities: {
            ...acc.entities,
            [list._id]: ListEntityModel.fromJson(list, {
              listsStore: this,
              rootStore: this.rootStore
            })
          },
          keys: [...acc.keys, list._id]
        }),
        { entities: {}, keys: [] }
      );

      runInAction(() => {
        list.addEntities({ entities, keys, initial: true });
        list.setLoadingStage(LoadingStage.SUCCESS);
        list.setIsInitialLoad(false);
      });
    } else {
      runInAction(() => {
        list.setLoadingStage(LoadingStage.ERROR);
      });
    }

    return {
      isError: response.isError
    };
  }

  async load(): Promise<BaseResponse> {
    if (!this.canBeLoaded) {
      return {
        isError: true
      };
    }

    const responses = await Promise.all(
      listEntityTypesOrder.map((lType) => this.loadList({ lType }))
    );

    if (responses.some((response) => response.isError)) {
      return {
        isError: true
      };
    }

    return {
      isError: false
    };
  }

  removeList({ id, lType }: { id: string; lType: ListEntityType }): void {
    const listsWithType = this.getList({ lType });

    if (!listsWithType) {
      return;
    }

    listsWithType.removeEntity(id);
  }

  async createList({
    lType,
    name
  }: {
    lType: ListEntityType;
    name: string;
  }): Promise<ListEntityModel | null> {
    const list = this.lists.get(lType);

    if (!list) {
      return null;
    }

    list.setCreatingStage(LoadingStage.LOADING);

    const response = await this.rootStore.networkStore.api<ListEntityServer>(
      apiUrls.LISTS_CREATE,
      {
        method: 'POST',
        data: {
          type: lType,
          name
        },
        errorsMap: mapListsCreateErrorCodeToMessage
      }
    );

    if (!response.isError) {
      const listEntity = ListEntityModel.fromJson(response.data, {
        listsStore: this,
        rootStore: this.rootStore
      });

      runInAction(() => {
        list.addEntity({ entity: listEntity, key: listEntity.id });
        list.setCreatingStage(LoadingStage.SUCCESS);
      });

      return listEntity;
    } else {
      runInAction(() => {
        list.setCreatingStage(LoadingStage.ERROR);
      });
      return null;
    }
  }

  reset(): void {
    this.lists.forEach((list) => list.reset());
    this.lists.clear();
  }
}
