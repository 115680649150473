import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const TBankShieldIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XL}
      svgProps={{
        viewBox: '0 0 32 32'
      }}
      {...props}
    >
      <path
        d="M2.5 3.5H29.5V17.081C29.5 20.564 27.6438 23.777 24.6265 25.5185L16 30.5L7.3735 25.5185C4.35625 23.777 2.5 20.5573 2.5 17.081V3.5Z"
        fill="#FFDD2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9115 10.6484V14.8739C10.492 14.2191 11.5382 13.7804 12.7397 13.7804H14.0425V18.6876C14.0425 19.9971 13.6847 21.1379 13.1582 21.7656H18.835C18.3085 21.1379 17.9575 19.9971 17.9575 18.6876V13.7736H19.2602C20.4617 13.7736 21.508 14.2124 22.0885 14.8671V10.6416H9.9115V10.6484Z"
        fill="#333333"
      />
    </BaseIcon>
  );
};

export default TBankShieldIcon;
