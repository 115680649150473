import { computed, makeObservable } from 'mobx';

import { FieldModel } from '../form';

export default class SmartCaptchaModel {
  readonly token: FieldModel<string> = new FieldModel<string>('');
  readonly visible: FieldModel<boolean> = new FieldModel<boolean>(false);
  readonly resetCaptcha: FieldModel<number> = new FieldModel<number>(0);

  constructor() {
    makeObservable(this, {
      failed: computed
    });
  }

  get failed(): boolean {
    return !this.token.value.length;
  }

  execute = (): void => {
    this.visible.changeValue(true);
  };

  reset = (): void => {
    this.resetCaptcha.changeValue(this.resetCaptcha.value + 1);
    this.token.changeValue('');
    this.visible.changeValue(false);
  };
}
