import { computed, makeObservable } from 'mobx';

import { IRootStore } from 'shared/entities/store/rootStore';
import { AIBotModel } from 'shared/models/smartbotAI/AIBotModel';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { ISmartbotAIBotsStore } from 'shared/entities/store/smartbotAIBotsStore';
import NewListModel from 'shared/models/NewListModel';
import { apiUrls } from 'shared/entities/domain';
import { AIBotServer } from 'shared/entities/smartbotAI';
import { FieldModel } from 'shared/models/form';
import { ComponentLoadingStore } from 'stores/componentLoadingStore';

export class SmartbotAIBotsStore
  extends ComponentLoadingStore
  implements ISmartbotAIBotsStore
{
  readonly rootStore: IRootStore;
  readonly bots: NewListModel<AIBotModel> = new NewListModel<AIBotModel>();
  readonly loadingStage: LoadingStageModel = new LoadingStageModel();
  readonly removingStage: LoadingStageModel = new LoadingStageModel();
  readonly searchText: FieldModel<string> = new FieldModel<string>('');

  constructor(rootStore: IRootStore) {
    super();
    this.rootStore = rootStore;

    makeObservable(this, {
      filteredBots: computed
    });
  }

  get loaded(): boolean {
    return this.loadingStage.successfullyLoaded;
  }

  get canBeLoaded(): boolean {
    return this.rootStore.initialized;
  }

  /*
   * проверка на вхождение searchText
   */
  get filteredBots(): AIBotModel[] {
    return this.bots.items.filter((bot) =>
      bot.name
        .split(' ')
        .some((word) =>
          word.toLowerCase().includes(this.searchText.value.toLowerCase())
        )
    );
  }

  async load(): Promise<BaseResponse> {
    if (this.loadingStage.isLoading) {
      return {
        isError: true
      };
    }
    this.loadingStage.loading();

    const response = await this.rootStore.networkStore.api<{
      ai_bots: AIBotServer[];
    }>(apiUrls.AI_BOTS_LIST);

    if (response.isError) {
      this.loadingStage.error();
      return {
        isError: true
      };
    }

    this.bots.fillByRawData({
      raw: response.data.ai_bots,
      normalizer: (rawItem) => {
        return {
          entity: AIBotModel.fromJson(rawItem, this.rootStore),
          key: rawItem._id
        };
      }
    });

    this.loadingStage.success();
    this.bots.isInitialLoadModel.changeValue(false);
    return {
      isError: false
    };
  }

  removeBot = async (id: string): Promise<BaseResponse> => {
    if (this.removingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.removingStage.loading();
    const response = await this.rootStore.networkStore.api(
      apiUrls.AI_BOTS_DELETE,
      {
        method: 'POST',
        data: {
          _id: id
        }
      }
    );

    if (response.isError) {
      this.removingStage.error();
      return {
        isError: true
      };
    }

    this.bots.removeEntity(id);

    this.removingStage.success();
    return {
      isError: false
    };
  };

  reset(): void {
    this.bots.reset();
    this.loadingStage.reset();
    this.removingStage.reset();
    this.searchText.changeValue('');
  }
}
