import * as React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { useUiStore } from 'stores';
import { CommonDirection } from 'shared/entities/common/client';
import { useResizableWindow } from 'shared/entities/common/hooks';

import './ResizeableWindow.modules.scss';

const EXTRA_WIDTH = 472;

type Props = {
  children: React.ReactNode;
};

const ResizeableWindow: React.FC<Props> = ({ children }: Props) => {
  const uiStore = useUiStore();
  const { onMouseDown, isDragging, width } = useResizableWindow({
    initialWidth: !uiStore.isMobileDevice ? EXTRA_WIDTH : undefined,
    directionOfExpansion: CommonDirection.left
  });

  return (
    <>
      <div
        styleName={cn(
          'resizeable-window',
          isDragging && 'resizeable-window_unselect'
        )}
        style={{ width: width }}
      >
        <div styleName="resizeable-window__bar" onMouseDown={onMouseDown} />
        {children}
      </div>
    </>
  );
};

export default observer(ResizeableWindow);
