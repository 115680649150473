import {
  QiwiIcon,
  YooKassaIcon,
  YooMoneyIcon,
  ProdamusIcon
} from 'shared/components/icons';
import {
  TelegramOriginalIcon,
  TBankShieldIcon
} from 'shared/newComponents/icons';
import { TelegramPaymentKind } from 'shared/entities/telegramPayments';

import { SelectorItem } from '../components/Selector';
import { TinkoffTaxation } from '../tinkoffPayment';

export enum UserPaymentMethodKind {
  yookassa = 'yookassa',
  qiwi = 'qiwi',
  yooMoney = 'yoomoney',
  prodamus = 'prodamus',
  tg = 'tg',
  tinkoff = 'tinkoff'
}

export const userPaymentMethodKindsWithReceipt = [
  UserPaymentMethodKind.yookassa,
  UserPaymentMethodKind.qiwi,
  UserPaymentMethodKind.tg,
  UserPaymentMethodKind.tinkoff
];

export const mapUserPaymentMethodKindEntities: Record<
  UserPaymentMethodKind,
  SelectorItem
> = {
  [UserPaymentMethodKind.yookassa]: {
    id: UserPaymentMethodKind.yookassa,
    title: 'ЮKassa',
    Icon: YooKassaIcon
  },
  [UserPaymentMethodKind.qiwi]: {
    id: UserPaymentMethodKind.qiwi,
    title: 'QIWI',
    Icon: QiwiIcon
  },
  [UserPaymentMethodKind.yooMoney]: {
    id: UserPaymentMethodKind.yooMoney,
    title: 'ЮMoney',
    Icon: YooMoneyIcon
  },
  [UserPaymentMethodKind.prodamus]: {
    id: UserPaymentMethodKind.prodamus,
    title: 'Prodamus',
    Icon: ProdamusIcon
  },
  [UserPaymentMethodKind.tg]: {
    id: UserPaymentMethodKind.tg,
    title: 'Telegram Payments',
    Icon: TelegramOriginalIcon
  },
  [UserPaymentMethodKind.tinkoff]: {
    id: UserPaymentMethodKind.tinkoff,
    title: (t) =>
      t('integrations.tinkoff.title', {
        ns: 'entities'
      }),
    Icon: TBankShieldIcon
  }
};

export const userPaymentMethodKindOrder: UserPaymentMethodKind[] = [
  UserPaymentMethodKind.yookassa,
  UserPaymentMethodKind.yooMoney,
  UserPaymentMethodKind.qiwi,
  UserPaymentMethodKind.prodamus,
  UserPaymentMethodKind.tg,
  UserPaymentMethodKind.tinkoff
];

export type UserPaymentBaseMethodServer<K extends UserPaymentMethodKind> = {
  _id: string;
  external_id: string;
  verbose_name: string;
  date_created: string;
  is_enabled: boolean;
  is_test: boolean;
  vat_code: VatCode;
  provider_kind: K;
};

export type UserPaymentCommonMethodServer = UserPaymentBaseMethodServer<
  | UserPaymentMethodKind.qiwi
  | UserPaymentMethodKind.prodamus
  | UserPaymentMethodKind.yookassa
>;

export type UserPaymentYooMoneyMethodServer =
  UserPaymentBaseMethodServer<UserPaymentMethodKind.yooMoney>;

export type UserPaymentProdamusMethodServer =
  UserPaymentBaseMethodServer<UserPaymentMethodKind.prodamus>;

export type UserPaymentQiwiMethodServer =
  UserPaymentBaseMethodServer<UserPaymentMethodKind.qiwi>;

export type UserPaymentTelegramMethodServer =
  UserPaymentBaseMethodServer<UserPaymentMethodKind.tg> & {
    extra: {
      tg_payment_provider: {
        kind: TelegramPaymentKind;
        channel_id: string;
      };
    };
  };

export type UserPaymentTinkoffMethodServer =
  UserPaymentBaseMethodServer<UserPaymentMethodKind.tinkoff> & {
    extra: {
      taxation: TinkoffTaxation;
    };
  };

export type UserPaymentMethodServer =
  | UserPaymentCommonMethodServer
  | UserPaymentYooMoneyMethodServer
  | UserPaymentTelegramMethodServer
  | UserPaymentTinkoffMethodServer;

export enum VatCode {
  noVat = 'no_vat',
  vat0 = 'vat_0',
  vat10 = 'vat_10',
  vat18 = 'vat_18',
  vat20 = 'vat_20',
  vat10_110 = 'vat_10_110',
  vat18_118 = 'vat_18_118',
  vat20_120 = 'vat_20_120'
}
