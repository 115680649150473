import { TranslationString } from 'shared/entities/localization';

import { Permission } from '../permissions';
import { ManagerExternalsServer } from '../user';

export enum AcceptInvitationErrorCode {
  managerAlreadyAdd = 'manager_already_exists',
  tokenExpired = 'expired_token',
  managerNotFound = 'manager_not_found',
  invalidToken = 'invalid_token'
}

export const mapAcceptInvitationErrorCodeToMessage =
  (code: AcceptInvitationErrorCode): TranslationString =>
  (t) =>
    t(`manager.acceptInvitationErrors.${code}`, {
      ns: 'entities'
    });

export enum CabinetManagerRoleType {
  owner = 'owner',
  admin = 'admin',
  moderator = 'moderator',
  spectator = 'spectator',
  billing = 'billing',
  guest = 'guest'
}

export enum ProjectManagerRoleType {
  admin = 'admin',
  ro = 'ro'
}

export type ManagerBaseServer = {
  name: string;
  email: string;
};

export type ProjectManagerEntityServer = {
  _id: string;
  name: string;
  role: ProjectManagerRoleType;
};

export type CabinetManagerServer = ManagerBaseServer & {
  cabinet_role: CabinetManagerRoleType | null;
  projects: ProjectManagerEntityServer[];
  is_role_changeable: boolean;
  external_ids: ManagerExternalsServer;
};

export type ProjectManagerServer = ManagerBaseServer & {
  project_role: ProjectManagerRoleType | null;
  is_role_changeable: boolean;
};

export type ManagerRoleEntity<R> = {
  role: R;
  permissions: Permission[];
};

export type CabinetManagerRoleEntity =
  ManagerRoleEntity<CabinetManagerRoleType>;
export type ProjectManagerRoleEntity =
  ManagerRoleEntity<ProjectManagerRoleType>;

export enum ProjectManagerAddingErrorCode {
  managerAlreadyExists = 'manager_already_exists'
}

export const mapProjectManagerAddingErrorCodeToMessage =
  (code: ProjectManagerAddingErrorCode): TranslationString =>
  (t) =>
    t(`manager.addingErrors.${code}`, {
      ns: 'entities'
    });

export enum ManagerRemovingErrorCode {
  managerSelfEdit = 'manager_self_edit'
}

export const mapManagerRemovingErrorCodeToMessage =
  (code: ManagerRemovingErrorCode): TranslationString =>
  (t) =>
    t(`manager.removingErrors.${code}`, {
      ns: 'entities'
    });
