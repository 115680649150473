import * as React from 'react';

import BaseIcon from 'shared/newComponents/icons/BaseIcon';
import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';

const TBankIcon: React.FC<CommonIconProps> = (props: CommonIconProps) => {
  return (
    <BaseIcon
      iconSize={IconSize.XXL}
      svgProps={{
        viewBox: '0 0 110 40'
      }}
      proportion={2.75}
      {...props}
    >
      <g clipPath="url(#clip0_8727_2614)">
        <path
          d="M54.9346 18.5831C54.2306 18.3122 53.3098 18.2311 52.3079 18.2311H46.7291V16.4979H56.3158V13.519H42.8024V26.4094H51.9558C53.2286 26.4094 54.1762 26.3011 54.8805 26.0302C56.37 25.4614 57.3719 24.0804 57.3719 22.266C57.3719 20.4247 56.4241 19.1518 54.9346 18.5831ZM52.9848 23.0514C52.7682 23.1327 52.4703 23.1597 52.091 23.1597H46.7291V21.1016H52.091C52.5245 21.1016 52.8764 21.1288 53.1473 21.2641C53.4722 21.4266 53.6347 21.7245 53.6347 22.1035C53.6347 22.4826 53.4452 22.8888 52.9848 23.0514Z"
          fill="#333333"
        />
        <path
          d="M68.3819 13.519H62.6409L57.3601 26.4094H61.8014L62.5326 24.5408H68.4902L69.2486 26.4094H73.6628L68.3819 13.519ZM63.5074 21.6973L65.3761 16.4979H65.6739L67.5425 21.6973H63.5074Z"
          fill="#333333"
        />
        <path
          d="M78.717 21.6158V26.409H74.7905V13.5186H78.717V18.2306H85.0269V13.5186H88.9536V26.409H85.0269V21.6158H78.717Z"
          fill="#333333"
        />
        <path
          d="M95.169 21.5345V26.409H91.2422V13.5186H95.169V18.366H96.2522L100.991 13.5186H105.92L99.6373 19.6929L106.164 26.409H100.937L96.2522 21.5345H95.169Z"
          fill="#333333"
        />
        <path
          d="M4.13153 5.23242H35.4365V20.9765C35.4365 25.0126 33.2833 28.7422 29.7882 30.7605L19.784 36.5373L9.77987 30.7605C6.28463 28.7422 4.13153 25.0126 4.13153 20.9765V5.23242Z"
          fill="#FFDD2D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.723 13.5186V18.4153C13.3926 17.6592 14.6101 17.1474 16.0021 17.1474H17.5149V22.8398C17.5149 24.3543 17.1034 25.6798 16.4928 26.409H23.0729C22.4637 25.6791 22.0531 24.3552 22.0531 22.8427V17.1474H23.5659C24.9579 17.1474 26.1754 17.6592 26.845 18.4153V13.5186H12.723Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_8727_2614">
          <rect
            width="102.222"
            height="32"
            fill="white"
            transform="translate(4 5)"
          />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export default TBankIcon;
