import * as React from 'react';
import cn from 'classnames';

import {
  TypographyColor,
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import { AppNotificationType } from 'shared/entities/appNotifications';
import {
  ButtonColor,
  ButtonContentPosition,
  ButtonStyle,
  ComponentButtonProps
} from 'shared/newEntities/components/Button';
import { TranslationNode } from 'shared/entities/localization';
import { ComponentType } from 'shared/entities/components/Component';
import TransComp from 'shared/components/TransComp';

import { CloseIcon, PointerArrowIcon, NotificationIcon } from '../icons';
import { Button } from '../buttons';
import Typography from '../Typography';

import './Banner.modules.scss';

const mapTypeToTitleColor: Record<AppNotificationType, TypographyColor> = {
  [AppNotificationType.success]: TypographyColor.success,
  [AppNotificationType.warning]: TypographyColor.yellow,
  [AppNotificationType.error]: TypographyColor.red,
  [AppNotificationType.info]: TypographyColor.main
};

const mapTypeToButtonColor: Record<AppNotificationType, ButtonColor> = {
  [AppNotificationType.success]: ButtonColor.green,
  [AppNotificationType.warning]: ButtonColor.red,
  [AppNotificationType.error]: ButtonColor.redTertiary,
  [AppNotificationType.info]: ButtonColor.blue
};

type Props = {
  type: AppNotificationType;
  title: TranslationNode;
  linkProps?: ComponentButtonProps;
  linkTitle?: TranslationNode;
  onClose: () => void;
};

const Banner: React.FC<Props> = ({
  type,
  title,
  linkProps,
  linkTitle,
  onClose
}: Props) => {
  return (
    <div styleName={cn('banner', `banner_${type}`)}>
      <NotificationIcon type={type} styleName="banner__icon" />
      <div styleName="banner__container">
        <Typography
          styleName="banner__text"
          fontType={TypographyType.text}
          fontSize={TypographySize.s}
          fontWeight={TypographyWeight.normal}
          fontColor={mapTypeToTitleColor[type]}
        >
          {title}
        </Typography>
        {linkProps && (
          <Button
            color={mapTypeToButtonColor[type]}
            RightIcon={PointerArrowIcon}
            style={ButtonStyle.transparent}
            withoutPadding
            fontWeight={TypographyWeight.heavy}
            contentPosition={ButtonContentPosition.start}
            {...linkProps}
          >
            <TransComp>{linkTitle}</TransComp>
          </Button>
        )}
      </div>
      <CloseIcon
        action={{
          type: ComponentType.svg,
          onClick: onClose
        }}
        styleName={cn('banner__close', `banner__close_${type}`)}
      />
    </div>
  );
};

export default React.memo(Banner);
