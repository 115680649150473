export const createDocumentationUrl = (path: string): string =>
  `https://docs.smartbotpro.ru/${path}`;

export const LANDING_DOMAIN = 'smartbotpro.ru';
export const LANDING_LINK = `https://${LANDING_DOMAIN}/`;

export const DOCUMENTATION_LINK = createDocumentationUrl('');

export const documentationLinks = {
  vars: createDocumentationUrl('scenarii/peremennye'),
  buttons: createDocumentationUrl('scenarii/knopki'),
  channel: createDocumentationUrl('nachalo-raboty/kak-podklyuchit-messendzher'),
  importProducts: createDocumentationUrl(
    'instrumenty-rosta/magaziny-vnutri-telegram/import-tovarov-iz-excel-faila'
  ),
  attachments: createDocumentationUrl(
    'scenarii/deistviya/otpravit-soobshenie#vlozheniya'
  ),
  chatMessageEvent: createDocumentationUrl(
    'scenarii/sobytiya-scenariya/soobshenie-ot-polzovately'
  ),
  contactOffer: createDocumentationUrl('dokumenty/dogovor-publichnoi-oferty'),
  socialNetwork: createDocumentationUrl(
    'nachalo-raboty/kak-podklyuchit-messendzher/privyazka-akkaunta-v-socialnoi-seti'
  ),
  addingTgChannel: createDocumentationUrl(
    'nachalo-raboty/kak-podklyuchit-messendzher/podklyuchenie-telegram'
  ),
  addingTgBot: createDocumentationUrl(
    'nachalo-raboty/kak-podklyuchit-messendzher/podklyuchenie-telegram/dobavlenie-bota-v-gruppovoi-chat-v-telegram'
  ),
  addingTgBusiness: createDocumentationUrl(
    'nachalo-raboty/kak-podklyuchit-messendzher/podklyuchenie-telegram/podklyuchenie-telegram-business'
  ),
  yookassa: createDocumentationUrl(
    'scenarii/integracii/yukassa-dlya-priema-platezhei'
  ),
  payments: createDocumentationUrl('scenarii/priem-oplaty'),
  tinkoffPayment: createDocumentationUrl(
    'scenarii/priem-oplaty/priem-oplaty-cherez-tinkoff'
  ),
  webhook: createDocumentationUrl('scenarii/sobytiya-scenariya/webhook'),
  httpRequest: createDocumentationUrl('scenarii/integracii/http-zapros'),
  googleTables: createDocumentationUrl('scenarii/integracii/google-tablicy'),
  addingViberBot: createDocumentationUrl(
    'nachalo-raboty/kak-podklyuchit-messendzher/podklyuchenie-viber'
  ),
  amoCRM: createDocumentationUrl('scenarii/integracii/amocrm'),
  // blocks
  notifyManagers: createDocumentationUrl(
    'scenarii/deistviya/otpravit-uvedomlenie'
  ),
  switchContext: createDocumentationUrl('scenarii/deistviya/smenit-kontekst'),
  conditionSwitch: createDocumentationUrl(
    'scenarii/usloviya/mnozhestvennye-usloviya'
  ),
  tooManyBlocks: createDocumentationUrl(
    'nachalo-raboty/podrobnee-o-scenariyakh'
  ),
  setTimer: createDocumentationUrl(
    'scenarii/deistviya/zaplanirovannye-perekhody'
  ),
  portalOut: createDocumentationUrl(
    'scenarii/deistviya/perekhod-v-drugoi-scenarii'
  ),
  removeFromList: createDocumentationUrl(
    'scenarii/deistviya/rabota-s-polzovatelem'
  ),
  addToList: createDocumentationUrl('scenarii/deistviya/rabota-s-polzovatelem'),
  executeSq: createDocumentationUrl('scenarii/deistviya/vypolnit-smartquery'),
  sendStats: createDocumentationUrl('scenarii/deistviya/zapisat-v-statistiku'),
  extractData: createDocumentationUrl('scenarii/deistviya/izvlech-dannye'),
  isGroupMember: createDocumentationUrl(
    'scenarii/usloviya/usloviya-vkontakte#proverka-podpiski'
  ),
  basic: createDocumentationUrl('scenarii/usloviya/prostye-usloviya'),
  userInput: createDocumentationUrl('scenarii/deistviya/polzovatelskii-vvod'),
  setVar: createDocumentationUrl('scenarii/deistviya/ustanovit-peremennuyu'),
  chatMessage: createDocumentationUrl(
    'scenarii/sobytiya-scenariya/soobshenie-ot-polzovatelya'
  ),
  sendMessage: createDocumentationUrl('scenarii/deistviya/otpravit-soobshenie'),
  // pre_filter
  preFilter: createDocumentationUrl('scenarii/reakcii#filtr-predobrabotki'),
  firstMessage: createDocumentationUrl(
    'scenarii/sobytiya-scenariya/pervoe-soobshenie'
  ),
  defaultAnswer: createDocumentationUrl(
    'scenarii/sobytiya-scenariya/net-podkhodyashego-scenariya'
  ),

  debugMode: createDocumentationUrl('nastroiki/proekty#rezhim-otladki'),
  eventsQueueMode: createDocumentationUrl(
    'nastroiki/proekty#poryadok-obrabotki-soobshenii'
  ),
  globalInput: createDocumentationUrl(
    'scenarii/sobytiya-scenariya/vvod-polzovatelya#globalnyi-vkhod'
  ),
  globalInputFromUser: createDocumentationUrl(
    'scenarii/sobytiya-scenariya/soobshenie-ot-polzovatelya#globalnyi-vkhod'
  ),
  sendMessageExtraSettings: createDocumentationUrl(
    'scenarii/deistviya/otpravit-soobshenie#dopolnitelnye-nastroiki-deistviya-otpravit-soobshenie'
  ),
  amoCRMAddLead: createDocumentationUrl(
    'scenarii/integracii/amocrm/blok-sozdat-sdelku'
  ),
  amoCRMEditLead: createDocumentationUrl(
    'scenarii/integracii/amocrm/blok-izmenit-sdelku'
  ),
  createJivoChannel: createDocumentationUrl(
    'nachalo-raboty/kak-podklyuchit-messendzher/podklyuchenie-jivo'
  ),
  createWazzupChannel: createDocumentationUrl(
    '/nachalo-raboty/kak-podklyuchit-messendzher/podklyuchenie-wazzup24'
  ),
  menuForTgBot: createDocumentationUrl('scenarii/menyu-dlya-telegram-bota'),
  privacyPolicy: createDocumentationUrl('dokumenty/politika-konfidencialnosti'),
  smartbotAI: createDocumentationUrl('scenarii/integracii/smartbot-ai'),
  connectChannel: createDocumentationUrl(
    'nachalo-raboty/kak-podklyuchit-messendzher/podklyuchenie-kanala-k-scenariyu'
  ),
  createShop: createDocumentationUrl(
    'instrumenty/magaziny-v-telegram/sozdanie-kataloga'
  ),
  telegramStars: createDocumentationUrl(
    'scenarii/priem-oplaty/priem-oplaty-s-pomoshyu-telegram-stars'
  ),
  paymentSystemConnection: createDocumentationUrl(
    'scenarii/priem-oplaty/podklyuchenie-platezhnoi-sistemy'
  )
};

export const LINK_TO_BLOG = `https://${LANDING_DOMAIN}/blog`;

export const LINK_TO_VK = 'https://vk.com/smartbot';
export const LINK_TO_TG = 'https://t.me/smartbotteam';

export const API_QIWI_LINK = 'https://qiwi.com/api';

export const YOOMONEY_NOTIFICATIONS_LINK =
  'https://yoomoney.ru/transfer/myservices/http-notification';

export const BOT_FATHER_LINK = 'https://t.me/BotFather';
export const VIBER_PARTNERS_LINK = 'https://partners.viber.com';
export const CREATE_REFERRAL_LINK =
  'https://t.me/partnership_smartbot?start=sitelk';

export const ELAMA_SUPPORT_MAIL_LINK = 'milo@elama.ru';

export const SUPPORT_MAIL_LINK = 'sales@support.smartbotpro.ru';

export const CREATE_VK_GROUP_LINK = 'https://vk.com/groups?w=groups_create';

export const JIVO_SUPPORT_LINK = 'info@jivosite.com';

export const JIVO_LINK = 'jivo.ru';

export const TG_PAYMENTS_AVAILABLE_CURRENCIES_LINK =
  'https://core.telegram.org/bots/payments#supported-currencies';

export const TG_PAYMENTS_STARS_LINK =
  'https://core.telegram.org/bots/payments-stars';

export const wazzupLinks = {
  referal: 'https://wazzup24.com/?utm_p=JvmidV',
  whatsappWabaDiff:
    'https://wazzup24.ru/help/how-to-configurate/waba-i-whatsapp/',
  connect: {
    waba: 'https://wazzup24.ru/help/how-to-configurate/add-a-whatsapp-api-singapore-channel/',
    whatsapp:
      'https://wazzup24.ru/help/how-to-configurate/podkljuchenie-whatsapp-personal/',
    ig: 'https://wazzup24.ru/help/how-to-configurate/podkljuchit-instagram-api/',
    tgBot: 'https://wazzup24.ru/help/how-to-configurate/telegram-bot/',
    tgPersonal:
      'https://wazzup24.ru/help/how-to-configurate/telegram-personal/',
    avito: 'https://wazzup24.ru/help/how-to-configurate/kak-podkljuchit-avito/'
  },
  howConnect: 'https://wazzup24.ru/help/api-ru/sposoby-podkljucheniya/'
};

export const PERSONAL_MANAGER_LINK = 'https://t.me/VladimirSmartBotPro';

export const PORTAL_IDS = {
  DRAG_LAYER_ID: 'drag-layer',
  MODALS: 'modals',
  SIDE_WINDOW: 'side-window',
  POPUPS: 'popup-root',
  YANDEX_METRIKA: '__yandex_metrika__',
  LIST_PAGE_BUTTONS: 'list-page-buttons'
};

export const MAIN_ELEMENTS_ID = {
  GRAPH_CONTAINER: '__graph_container__',
  SIDE_WINDOW_CONTAINER: '__side-window-container__'
};
