import { LocalStorageKey } from 'shared/entities/localStorage';
import { localStorageHandler } from 'stores/localStorageHandler';

const getBooleanValueFromLS = (
  key: LocalStorageKey | string
): boolean | null => {
  const valueFromLS = localStorageHandler.getItem(key);

  if (valueFromLS === null) {
    return null;
  }

  try {
    const parsedValueFromLS = JSON.parse(valueFromLS);

    if (typeof parsedValueFromLS !== 'boolean') {
      return null;
    }

    return parsedValueFromLS;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('parse value from LocalStorage error');

    return null;
  }
};

export default getBooleanValueFromLS;
