import * as React from 'react';
import * as Sentry from '@sentry/browser';

import { urls } from 'shared/entities/domain';
import { ComponentType } from 'shared/entities/components/Component';
import TypedTransComponent from 'shared/components/TypedTransComponent';
import { useRouterStore } from 'stores';

import Button from '../buttons/Button';
import ErrorBoundary, { ErrorBoundaryData } from '../ErrorBoundary';
import TransComp from '../TransComp';

import './SentryErrorBoundary.modules.scss';

type Props = {
  children?: React.ReactNode;
};

const ErrorComponent: React.FC<ErrorBoundaryData> = ({ error }) => {
  const routerStore = useRouterStore();
  const handleMain = React.useCallback(() => {
    routerStore.changeToAuthDomain({ path: urls.ROOT.mask });
  }, []);

  const errorInfo = React.useMemo(() => {
    if (error instanceof SyntaxError) {
      return (
        <TransComp>
          {(t) => t('SentryErrorBoundary.info', { ns: 'components' })}
        </TransComp>
      );
    }
    return null;
  }, [error]);

  return (
    <div styleName="error">
      <div styleName="error__content">
        <TypedTransComponent
          ns="components"
          i18nKey="SentryErrorBoundary.content"
        >
          Произошла неизвестная ошибка
          <Button
            type={ComponentType.button}
            styleName="error__button"
            onClick={handleMain}
          >
            Перейти на главную
          </Button>
        </TypedTransComponent>
        {errorInfo && <div styleName="error__info">{errorInfo}</div>}
      </div>
    </div>
  );
};

const SentryErrorBoundary: React.FC<Props> = ({ children }: Props) => {
  const onError = React.useCallback((error: Error, info: React.ErrorInfo) => {
    // eslint-disable-next-line no-console
    console.log('caught', error, info);

    Sentry.withScope((scope) => {
      // @ts-ignore
      scope.setExtras(info);
      Sentry.captureException(error);
    });
  }, []);

  return (
    <ErrorBoundary onError={onError} ErrorComponent={ErrorComponent}>
      {children}
    </ErrorBoundary>
  );
};

export default SentryErrorBoundary;
