import { TimeoutServer } from './server';
import { ITimeout, ValidIntervalInSeconds } from './client';

export const normalizeTimeout = (
  raw: TimeoutServer,
  withVarInterval = false,
  validIntervalInSeconds: ValidIntervalInSeconds | null = null,
  validInterval
): ITimeout => ({
  interval: raw.interval,
  unit: raw.unit,
  withVarInterval,
  validIntervalInSeconds,
  validInterval
});
